export const BID_PRODUCT = 'BID_PRODUCT'
export const GET_VIEW_PRODUCT = 'GET_VIEW_PRODUCT'
export const GET_BID_HISTORY = 'GET_BID_HISTORY'
export const RESPONSE_STATUS = 'RESPONSE_STATUS'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const GET_ALL_BUYER = 'GET_ALL_BUYER'
export const GET_ALL_TRANSACTION = 'GET_ALL_TRANSACTION'
export const GET_BUYERS_LIST = 'GET_BUYERS_LIST'
export const GET_CHAT_HISTORY = 'GET_CHAT_HISTORY'
export const GET_SHIPPING_RATE = 'GET_SHIPPING_RATE'
export const GET_MAKEREQUEST_LIST = 'GET_MAKEREQUEST_LIST'
export const CLEAR_BUYER_STATE = 'CLEAR_BUYER_STATE'
export const GET_MORE_VIEW_PRODUCT = 'GET_MORE_VIEW_PRODUCT'
export const SET_IMAGES = 'SET_IMAGES'
export const CLEAR_IMAGES = 'CLEAR_IMAGES'
