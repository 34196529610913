import React, { useContext, useEffect } from 'react'
import CustomInput from '../components/atoms/Inputs/CustomInput'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CartContext from '../../Product/context/cart/cartContext'
import { currencyFormat, handleRedirectInternal } from './components'
import PrimaryButton from '../components/atoms/PrimaryButton'
import authContext from '../context/auth/authContext'
const Qtyinputcard = (props) => {
    const cartContext = useContext(CartContext)
    const { isAuthenticated } = useContext(authContext)
    const { updateFromCart, addToCart, responseStatus, getUserCart, removeFromCart } = cartContext
    const formik = useFormik({
        initialValues: {
            qty: props.qty,
            cart_id: props.cart_id,
            product_id: props.product_id,
        },
        validationSchema: Yup.object().shape({
            qty: Yup.number()
                .max(parseInt(props.total_qty), 'Qty Invalid!')
                .positive('Qty Invalid!')
                .typeError('Qty Invalid!')
                .integer('Qty Invalid!')
                .required('Please Enter Qty'),
        }),
        enableReinitialize: true,
        onSubmit: (values) => {
            addToCart({ id: props.product_id, qty: values.qty })
        },
    })

    const removefromCart = () => {
        removeFromCart({ id: props.product_id })
    }

    useEffect(() => {
        formik.setFieldValue('cart_id', props.cart_id)
        formik.setFieldValue('product_id', props.product_id)
    }, [props])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'updateusercart') {
                if (responseStatus.status === 'success') {
                    getUserCart()
                }
            }
        }
    }, [responseStatus])

    return (
        <>
            {props.type == 'slider' ? (
                <div className="col-12 sliding">
                    {!isAuthenticated ? (
                        <PrimaryButton
                            onClick={(e) => handleRedirectInternal(props.history, 'login')}
                        >
                            Login To Buy Now
                        </PrimaryButton>
                    ) : props.disabled ? (
                        <PrimaryButton onClick={(e) => removefromCart()}>
                            Remove From Cart
                        </PrimaryButton>
                    ) : (
                        <>
                            <CustomInput
                                value={formik.values.qty}
                                onChange={formik.handleChange}
                                name="qty"
                                placeholder={'Enter Qty'}
                                type={'number'}
                                min={1}
                                error={
                                    formik.filter &&
                                    formik &&
                                    formik.touched &&
                                    formik.touched.filters &&
                                    formik.touched.filters['qty'] &&
                                    formik.errors &&
                                    formik.errors.filters &&
                                    formik.errors.filters['qty']
                                        ? formik.errors.filters['qty']
                                        : formik.touched['qty'] && formik.errors['qty']
                                }
                                helperText={
                                    formik &&
                                    formik.touched &&
                                    formik.touched.filters &&
                                    formik.touched.filters['qty'] &&
                                    formik.errors &&
                                    formik.errors.filters &&
                                    formik.errors.filters['qty']
                                        ? formik.errors.filters['qty']
                                        : formik.touched['qty'] && formik.errors['qty']
                                }
                            />
                            <PrimaryButton onClick={(e) => formik.handleSubmit()}>
                                Add To Cart
                            </PrimaryButton>
                        </>
                    )}
                </div>
            ) : (
                <div className="col-12 newSlide">
                    {!isAuthenticated ? (
                        <PrimaryButton
                            onClick={(e) => handleRedirectInternal(props.history, 'login')}
                        >
                            Login To Buy Now
                        </PrimaryButton>
                    ) : props.disabled ? (
                        <PrimaryButton onClick={(e) => removefromCart()}>
                            Remove From Cart
                        </PrimaryButton>
                    ) : (
                        <>
                            <CustomInput
                                value={formik.values.qty}
                                onChange={formik.handleChange}
                                name="qty"
                                placeholder={'Enter Qty'}
                                type={'number'}
                                min={1}
                                error={
                                    formik.filter &&
                                    formik &&
                                    formik.touched &&
                                    formik.touched.filters &&
                                    formik.touched.filters['qty'] &&
                                    formik.errors &&
                                    formik.errors.filters &&
                                    formik.errors.filters['qty']
                                        ? formik.errors.filters['qty']
                                        : formik.touched['qty'] && formik.errors['qty']
                                }
                                helperText={
                                    formik &&
                                    formik.touched &&
                                    formik.touched.filters &&
                                    formik.touched.filters['qty'] &&
                                    formik.errors &&
                                    formik.errors.filters &&
                                    formik.errors.filters['qty']
                                        ? formik.errors.filters['qty']
                                        : formik.touched['qty'] && formik.errors['qty']
                                }
                            />

                            <PrimaryButton onClick={(e) => formik.handleSubmit()}>
                                Add To Cart
                            </PrimaryButton>
                        </>
                    )}
                </div>
            )}
        </>
    )
}

export default Qtyinputcard
