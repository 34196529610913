import React from 'react'
import PostProduct from '../PostProduct'
const Step2 = (props) => {
    const { auction_id, setActiveStep } = props
    return (
        <div>
            <PostProduct
                auction_id={auction_id}
                setActiveStep={setActiveStep}
                addressSection={props.addressSection}
                buynow={props.buynow}
                extraSection={props.extraSection}
                foodIndustryFields={props.foodIndustryFields}
                theme={props.theme}
                taxFree={props.taxFree}
                defaultCountryCode={props.defaultCountryCode ? props.defaultCountryCode : 38}
                bottomErrorField={props.bottomErrorField}
                low_and_high_est={props.low_and_high_est}
            />
        </div>
    )
}

export default Step2
