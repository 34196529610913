import React, { useEffect, useState, useContext } from 'react'
import GridProduct from './gridProduct'
import _ from 'lodash'
import SlidePanel from '../SlidePanel'
import MultisellerSlider from '../SlidePanel/MultisellerSlider'
import { useHistory, useLocation } from 'react-router'
import MultisellerListCard from './MultisellerListCard'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
// let gridItems = [];

const GridListComponent = (props) => {
    const history = useHistory()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const handleClose = () => {
        params.delete('product')
        history.replace({
            pathname: location.pathname,
            search: params.toString(),
        })
        setSelectedProduct(null)
    }
    const handleClick = async (id) => {
        // setSelectedProduct(id)
        // console.log('################################+')
        if (props.fromComponent === 'home') {
            setSelectedProduct(id)
        } else {
            params.set('product', id)

            history.replace({
                pathname: location.pathname,
                search: params.toString(),
            })
        }
    }
    useEffect(() => {
        // console.log('++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++')
        var product_id = params.get('product')
        if (product_id && !selectedProduct) {
            setSelectedProduct(product_id)
        }
    }, [window.location.search])
    const categoryTypes = {}
    global.allCategories.map((catgeory) => (categoryTypes[catgeory.id] = catgeory))
    return (
        <div className="search-wrapper">
            {props.cardType == 'list' ? (
                <>
                    {props.searchResult.map((item, index) => {
                        return (
                            <LazyLoadComponent>
                                <div className={props.className} key={`gl-${index}`}>
                                    <MultisellerListCard
                                        lotData={item}
                                        setViewProduct={props.setViewProduct}
                                        viewProduct={props.searchResult}
                                        key={index}
                                        selectedProduct={handleClick}
                                        type="lot"
                                        handleClick={handleClick}
                                        details={`/gallery/product-view/${item.id}`}
                                        sliderTheme="multiseller"
                                        bidBtnLabel={
                                            <>
                                                <span className="material-icons-outlined">
                                                    gavel
                                                </span>
                                                Bid
                                            </>
                                        }
                                    />
                                </div>
                            </LazyLoadComponent>
                        )
                    })}
                </>
            ) : (
                <div className="gridContainer">
                    {props.searchResult.map((item, index) => {
                        return (
                            <LazyLoadComponent>
                                <div className={props.className} key={`gl-${index}`}>
                                    <>
                                        <GridProduct
                                            data={item}
                                            lotImages={item.lotImages}
                                            link={`/gallery/product-view/${item.id}`}
                                            handleClick={handleClick}
                                            timerTheme={props.timerTheme}
                                            type={props.type ? props.type : ''}
                                            cardTheme={props.cardTheme}
                                            noTimer={props.noTimer}
                                            getAllProducts={props.getAllProducts}
                                            setViewProduct={props.setViewProduct}
                                            viewProduct={props.searchResult}
                                            audio={props.audio}
                                            productViewLink={
                                                props.cardTheme === 'auctioneer'
                                                    ? `productview/${item.id}/${
                                                          item.auction ? 'auction' : 'buynow'
                                                      }/${
                                                          (item?.custom_field_9?.includes('}')
                                                              ? JSON.parse(item.custom_field_9)
                                                              : {}
                                                          )?.game_theme
                                                              ?.toLowerCase()
                                                              ?.split(' ')
                                                              ?.join('-') +
                                                          '-' +
                                                          item?.subcategory
                                                              ?.split(',')
                                                              ?.map(
                                                                  (val) =>
                                                                      categoryTypes[
                                                                          val
                                                                      ]?.description?.toLowerCase() ??
                                                                      '',
                                                              )
                                                              ?.join('-')
                                                              .split(' ')
                                                              .join('-')
                                                      }`
                                                    : props.productViewLink
                                            }
                                        />
                                    </>
                                </div>
                            </LazyLoadComponent>
                        )
                    })}
                </div>
            )}

            {props.sliderTheme == 'multiseller' ? (
                <MultisellerSlider
                    selectedLot={selectedProduct}
                    handleClose={handleClose}
                    type={props.type ? props.type : ''}
                    fromhomecomponants={1}
                />
            ) : props.sliderTheme == 'Collectibles' ? (
                <MultisellerSlider
                    storeTheme="ArtAuction"
                    selectedLot={selectedProduct}
                    handleClose={handleClose}
                    type={props.type ? props.type : ''}
                    conditions={'Collectibles'}
                />
            ) : (
                <SlidePanel
                    selectedLot={selectedProduct}
                    handleClose={handleClose}
                    type={props.type ? props.type : ''}
                    getAllProducts={props.getAllProducts}
                    enableZoomOnHover={props.enableZoomOnHover}
                />
            )}

            {/* {console.log(props.enableZoomOnHover, 'enableZoomOnHoverInner')} */}
        </div>
    )
}

export default GridListComponent
