import React, { useEffect, useState, useContext, useRef } from 'react'
import MessageBody from './MessageBody'
import './Messages.css'
import UserCard from './UserCard'
import BuyerContext from '../../../Product/context/buyer/buyerContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'
import { socket } from '../../../Product/common/socket'

const UserMessage = ({ project_id, backImage }) => {
    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)
    const { list_buyers, chat_history, listAllBuyers, getChatHistory, updateUserMessageCount } =
        buyerContext
    const { user, updateMessageCount, userCount } = authContext
    const [chated_history, setChatHistory] = useState([])
    const [chat_display, setChatDisplay] = useState(true)
    const [viewUsers, setViewUsers] = useState(false)
    const isMobile = useMediaQuery({
        query: '(max-width: 700px)',
    })
    const [buyersValue, setBuyersValue] = useState({
        limit_value: 0,
        search_key: ' ',
        user_role: project_id ? undefined : user?.role == 1 ? '' : 1,
        project_id,
    })
    const { t } = useTranslation()
    const [chatUser, setChatUser] = useState({})
    const listBuyersRef = useRef(list_buyers)
    const userCountRef = useRef(userCount)
    useEffect(() => {
        listBuyersRef.current = list_buyers
        userCountRef.current = userCount
    })
    const params = new URLSearchParams(window.location.search.substring(1))
    const updatechatuser = (data) => {
        if (parseInt(data.msg_count) > 0) {
            updateMessageCount(
                parseInt(userCountRef?.current?.messageCount) - parseInt(data.msg_count),
                parseInt(userCountRef?.current?.messageCountProjects),
            )
            data.msg_count = 0
        }
        setChatDisplay(false)
        setChatUser(data)
        setTimeout(() => {
            setChatDisplay(true)
        }, 500)
    }

    // useEffect(() => {
    //     setChatDisplay(true)
    // }, [chatUser])

    useEffect(() => {
        if (user) {
            listAllBuyers({
                ...buyersValue,
                user_role: project_id ? undefined : user?.role == 1 ? '' : 1,
            })
        }
    }, [buyersValue, user])
    useEffect(() => {
        if (user) {
            if (Object.keys(chatUser).length) {
                getChatHistory({
                    chat_id: chatUser.id,
                    project_id: project_id,
                    remove_project_id: project_id ? undefined : 1,
                })
            }
        }
    }, [chatUser, user])
    useEffect(() => {
        if (list_buyers.length) {
            // list_buyers.map((data) => {
            //     if (parseInt(data.msg_count) > 0 && parseInt(chatUser.id) == parseInt(data.id)) {
            //         // updateMessageCount(parseInt(userCount?.messageCount) - parseInt(data.msg_count))
            //         // data.msg_count = 0
            //         // setChatUser(data)
            //         updatechatuser(data)
            //     }
            // })
            // if (!Object.keys(chatUser).length) {
            if (params.get('user')) {
                let user = list_buyers.find((data) => data.id == params.get('user'))
                // updateMessageCount(
                //     parseInt(userCount?.messageCount) -
                //         parseInt(user ? user.msg_count : list_buyers[0]?.msg_count),
                // )
                console.log(user)
                if (user) {
                    updatechatuser(user)
                }
                // return updatechatuser(user ? user : list_buyers[0])
            }
            // updateMessageCount(
            //     parseInt(userCount?.messageCount) - parseInt(list_buyers[0]?.msg_count),
            // )
            // updatechatuser(list_buyers[0])
            // }
        }
    }, [list_buyers])

    useEffect(() => {
        if (chat_history?.length > 0 && Object.keys(chatUser).length) {
            setChatHistory(chat_history)
        } else {
            setChatHistory([])
        }
    }, [chat_history])

    // useEffect(() => {
    //     if (user) {
    //         socket.on('insertMessages_count', (data) => {
    //             if (listBuyersRef.current?.length) {
    //                 listBuyersRef.current.map((val) => {
    //                     if (val.id == data.from_id && !data.project_id) {
    //                         val.msg_count += 1
    //                     }
    //                 })
    //                 updateUserMessageCount(listBuyersRef.current)
    //             }
    //         })
    //     }

    //     return () => {
    //         socket.off('insertMessages_count', (data) => {
    //             return data
    //         })
    //     }
    // }, [user])
    return (
        <>
            {isMobile && (
                <Button className="mb-2" onClick={() => setViewUsers(!viewUsers)}>
                    <span className="material-icons mr-1">contacts</span> User List
                </Button>
            )}
            <div className="msgPgWrpr">
                <div className={`lftMsgWrpr ${viewUsers ? 'showUsers' : ''}`}>
                    <div className="topCntnr messageSearch">
                        <div className="srchBoxCntnr">
                            <span className="material-icons">search</span>
                            <input
                                placeholder={t('search')}
                                onChange={(e) =>
                                    setBuyersValue({
                                        ...buyersValue,
                                        search_key: e.target.value === '' ? ' ' : e.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="usrListWrpr" onClick={() => setViewUsers(false)}>
                        {list_buyers
                            .sort((a, b) => b.msg_count - a.msg_count)
                            .map((data) => (
                                <UserCard
                                    id={data.id}
                                    f_name={data.first_name}
                                    l_name={data.last_name}
                                    msg={data.message}
                                    time={data.date_sent}
                                    msg_count={data.msg_count}
                                    onClick={() => updatechatuser(data)}
                                    selected_id={chatUser.id}
                                />
                            ))}
                    </div>
                </div>
                <div className="rtMsgWrpr">
                    {chat_display ? (
                        <MessageBody
                            chatHistory={chated_history}
                            chatUser={chatUser}
                            backImage={backImage ? backImage : ''}
                            project_id={project_id}
                            chat_display={chat_display}
                        />
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </>
    )
}

export default UserMessage
