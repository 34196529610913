import React, { useState, useEffect, useContext, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import ProductContext from '../../../Product/context/product/productContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import AuthContext from '../../../Product/context/auth/authContext'
import PropTypes from 'prop-types'
import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
    jsonToFormData,
} from '../../../Product/common/components'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import './moredetails.css'
import './PostProduct.css'
import { Box, Divider, Tab, Tabs, Typography, InputAdornment } from '@material-ui/core'
import readXlsxFile from 'read-excel-file'
import moment from 'moment'
import {
    allCondition,
    storageOptions,
    unitOptions,
    sustainabilityOptions,
    brandsliqutatios,
} from '../..'
import CategoryLookup from '../CategoryLookup'
import { swapCountry } from '../../commonFunctions'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import { useTranslation } from 'react-i18next'
import Popup from '../../../Product/components/organisms/Popup'
import ListingFee from './ListingFee'
import Moredetails from './moredetails'

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `buyer-dashboard-${index}`,
        'aria-controls': `buyer-dashboard-tabpanel-${index}`,
    }
}

const PostProduct = (props) => {
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const { t } = useTranslation()
    var item_count = 0,
        total_items_csv = 0
    const customize = {
        withoutAuction: props.withoutAuction,
        itemLocation: props.itemLocation,
        addressSection: props.addressSection,
        buynow: props.buynow,
        extraSection: props.extraSection,
        foodIndustryFields: props.foodIndustryFields,
        noLocalPickup: props.noLocalPickup,
        removeAuctionSection: props.removeAuctionSection,
        pickupDetails: props.pickupDetails,
        externalshipingOnly: props.externalshipingOnly,
        freeShipping: props.freeShipping,
        liquidationPalletFields: props.LiquidationPalletFields,
        multiSectionImage: props.multiSectionImage,
        imageInfoContent: props.imageInfoContent,
        artauctionfields: props.artauctionfields,
        categoryNotRequired: props.categoryNotRequired,
        hideSubCategory: props.hideSubCategory,
        customCondition: props.customCondition,
        customLabel: props.customLabel,
        skuNotRequired: props.skuNotRequired,
        showOverallError: props.showOverallError,
        shipLabel: props.shipLabel,
        low_high_est_not_required: props.low_high_est_not_required,
        sku_not_required: props.sku_not_required,
        collectablethemefield: props.collectablethemefield,
        need_more_details: props.Need_more_details,
        need_to_disable_start_and_endate: props.need_to_disable_start_and_endate,
        compress_image: props.compress_image,
        set_start_and_enddate: props.set_start_and_enddate,
        change_date_time: props.change_date_time,
        low_est_field_as_buynow: props.low_est_as_buynow,
        low_and_high_est: props.low_and_high_est,
    }
    const { setAlert, clearAlert } = alertContext
    const {
        postProduct,
        postAuction,
        editProduct,
        edit_product,
        auction_lots,
        edit_auction,
        categories,
        bulkUploadInventory,
        getActiveUsers,
        all_active_users,
        getEditProduct,
        getEditAuction,
        getOverAllCategories,
        getAllbrands,
        brandsproduct,
        responseStatus,
        clearResponse,
    } = productContext
    const authContext = useContext(AuthContext)
    const { isAuthenticated, isAdmin, user } = authContext
    const [reload, setReload] = useState(false)
    const [loading, setLoading] = useState(false)
    const [alldata, setAlldata] = useState(false)
    const history = useHistory()
    const [addMore, setAddMore] = useState(0)
    const [value, setValue] = useState(0)
    const product_id = new URLSearchParams(window.location.search.substring(1)).get('id')
    const type = new URLSearchParams(window.location.search.substring(1)).get('type')
    const repost = new URLSearchParams(window.location.search.substring(1)).get('repost')
    const lotRef = useRef(null)
    const [stateList, setStateList] = useState([])
    const [countryList, setCountryList] = useState([])
    const [subCategory, setSubCategory] = useState([])
    const [posting_without_onboard, setPosting_without_onboard] = useState(false)
    const [listingFeePopup, setListingFeePopup] = useState(false)
    const [countrycode, setCountrycode] = useState()
    const [auctionDetails, setAuctionDetails] = useState({
        title: '',
        description: '',
        start_date: null,
        end_date: null,
        lot_id: [],
        status: 'open',
        auctionid: 0,
        private_auction: 0,
        selectedbuyers: [],
        grouping: 1,
        ending_enable: 0,
        ending_items: 0,
        ending_mints: 0,
        la_seller_id: 0,
        product_image: [],
        product_existingimage: [],
        product_imagefile: [],
        localpickup: customize.noLocalPickup ? 0 : 1,
        shipping: customize.externalshipingOnly ? 1 : 0,
        //address
        address: '',
        country: '',
        state: '',
        city: '',
        zipcode: '',
        phone: '',
    })
    useEffect(() => {
        if (edit_auction && auction_id) {
            let editAuctionDetails = edit_auction.auction_details
            var items_limit = global.storeConfigration?.global_lot_items?.value
                ? global.storeConfigration?.global_lot_items?.value
                : 0
            if (parseInt(editAuctionDetails.lot_count) > 0 && parseInt(items_limit) > 0) {
                var total_items = editAuctionDetails.lot_count
                item_count = editAuctionDetails.lot_count
                total_items_csv = editAuctionDetails.lot_count
                if (parseInt(items_limit) <= parseInt(total_items)) {
                    setAlert('Your Lot Limit Is Reached!', 'error')
                    handleRedirectInternal(history, `dashboard/overview`)
                }
            }
            //console.log(editAuctionDetails, 'editAuctionDetails')
            setAuctionDetails({
                title: editAuctionDetails.title,
                description: editAuctionDetails.description,
                start_date: editAuctionDetails.date_added,
                end_date: editAuctionDetails.date_closed,
                private_auction:
                    editAuctionDetails.private_auction &&
                    parseInt(editAuctionDetails.private_auction) > 0
                        ? 1
                        : 0,
                status: editAuctionDetails.market_status,
                ending_enable: editAuctionDetails.ending_enable,
                ending_items: editAuctionDetails.ending_items,
                ending_mints: editAuctionDetails.ending_mints,
                auctionid: auction_id,
                la_seller_id: editAuctionDetails.la_seller_id,
                product_existingimage: [editAuctionDetails.avatar],
                address: editAuctionDetails.address,
                country: editAuctionDetails.country,
                state: editAuctionDetails.state,
                city: editAuctionDetails.city,
                zipcode: editAuctionDetails.zipcode,
                phone: editAuctionDetails.custom_field_1,
                lot_id: auction_lots.length
                    ? auction_lots
                          .filter((i) => (i.lotof === auction_id ? true : false))
                          .map((dat) => dat.id)
                    : [],
                coupon_code: editAuctionDetails.coupon_code ? editAuctionDetails.coupon_code : '',
            })
        }
    }, [edit_auction, auction_lots])
    /*eslint-disable*/
    const { auction_id, setActiveStep } = props
    useEffect(() => {
        if (isAuthenticated) {
            if (user.role === '1') {
            } else {
                handleRedirectInternal(history, '')
            }
        }
    }, [isAuthenticated])
    useEffect(() => {
        // //console.log('data===>', (formik.values.country = user.country), user)
        //console.log('hiiiiiii', user)
        if (user) {
            formik.values.address = user.address1 || ''
            formik.values.city = user.city || ''
            formik.values.country = user.country
            formik.values.state = user.state
            formik.values.zipcode = user.zip || ''
            formik.setFieldValue('country', user.country)
        }
    }, [user])
    useEffect(() => {
        if (customize.withoutAuction) {
            return false
        }
        if (isAuthenticated) {
            if (auction_id) {
                getEditAuction({ auctionid: auction_id })
            }
        }
    }, [isAuthenticated, auction_id])

    const validationArray = Yup.object({
        product_id: Yup.number(),
        auctionid: Yup.number(),
        category_id: Yup.string().when('categoryNotRequired', {
            is: 0,
            then: Yup.string().required(t('category') + ' ' + t('required') + '!'),
        }),
        radcondition:
            global.storeConfigration?.condition_not_required?.value == '1'
                ? Yup.string()
                : Yup.string().required(t('condition') + ' ' + t('required') + '!'),
        subcategory: Yup.string(),
        protitle: Yup.string().required(t('title') + ' ' + t('required') + '!'),
        auction: Yup.boolean().when('buynow', {
            is: false,
            then: Yup.boolean().oneOf([true], 'Enable Auction or Buy Now'),
        }),
        sprice: Yup.number().when('auction', {
            is: true,
            then: Yup.number()
                .required(t('auction_start_pri') + ' ' + t('required') + '!')
                .positive('Should be greater than 1')
                .min(1, 'Should be greater than 1'),
        }),
        rprice: Yup.number().when('auction', {
            is: true,
            then: Yup.number().when('enable_rprice', {
                is: true,
                then: Yup.number()
                    .positive('Should be greater than 1')
                    .min(Yup.ref('sprice'), t('shoud_be_equal_or_graater'))
                    .required(t('auction_reserve_p') + ' ' + t('required') + '!'),
            }),
        }),
        // zipcode: Yup.string().when('addressSection', {
        //     is: 1 || true,
        //     then: Yup.string()
        //         .trim()
        //         .required(t('Zip_code') + ' ' + t('required') + '!'),
        // }),
        state:
            customize.addressSection &&
            (props.defaultCountryCode === undefined || props.defaultCountryCode == '')
                ? Yup.string().required('This is Required')
                : '',
        zipcode:
            countrycode != 156
                ? Yup.string()
                      .when('addressSection', {
                          is: 1 || true,
                          then: Yup.string().when('location', {
                              is: '38',
                              then: Yup.string()
                                  .matches(
                                      /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
                                      'Invalid Zipcode',
                                  )
                                  .required('This is Required'),
                              otherwise: Yup.string()
                                  .matches(/\b\d{5}\b/g, 'Invalid Zipcode!')
                                  .required('This is Required'),
                          }),
                      })
                      .when('country', {
                          is: (val) => val != 156,
                          then: Yup.string().required('This is Required'),
                          otherwise: Yup.string().notRequired(),
                      })
                : '',
        enable_rprice: Yup.boolean(),
        selectedbuyers: Yup.array(),
        prev_rprice: Yup.number(),
        buynow: Yup.boolean(),
        bprice: Yup.number().when('buynow', {
            is: true,
            then: Yup.number()
                .required('Buynow Price Required!')
                .positive('Should be greater than 1')
                .when('auction', {
                    is: true,
                    then: Yup.number().when('enable_rprice', {
                        is: true,
                        then: Yup.number().moreThan(
                            Yup.ref('rprice'),
                            'Should be greater than the reserve price',
                        ),
                        otherwise: Yup.number().moreThan(
                            Yup.ref('sprice'),
                            'Should be greater than the Start price',
                        ),
                    }),
                }),
        }),
        // startdate: Yup.string().required('Required!').nullable(),
        description: Yup.string().trim().required('Description Required!'),
        sku:
            customize.skuNotRequired || customize.sku_not_required == 1
                ? Yup.string()
                : Yup.string().required(t('sku') + ' ' + t('required') + '!'),
        qty: Yup.number().required('Quantity Required!').positive('Should be greater than 1'),
        lowest:
            global.storeConfigration?.disable_estimate?.value == 1 ||
            customize.low_high_est_not_required == 1
                ? Yup.number()
                : customize.low_est_field_as_buynow
                ? Yup.number()
                      .required(t('low_est') + ' ' + t('required') + '!')
                      .positive(t('should_be_greater_than_equal'))
                      .when('enable_rprice', {
                          is: true,
                          then: Yup.number().moreThan(
                              Yup.ref('rprice'),
                              t('should_greater_than_reserve_price'),
                          ),
                      })
                      .moreThan(Yup.ref('sprice'), t('should_be_greater_than_start_price'))
                : customize.low_and_high_est
                ? Yup.number()
                      .required(t('low_est') + ' ' + t('required') + '!')
                      .positive(t('should_be_greater_than_equal'))
                : Yup.number().when('auction', {
                      is: true,
                      then: Yup.number().required(t('low_est') + ' ' + t('required') + '!'),
                  }),
        highest:
            global.storeConfigration?.disable_estimate?.value == 1 ||
            customize.low_high_est_not_required == 1
                ? Yup.number()
                : customize.low_est_field_as_buynow
                ? Yup.number()
                      .required(t('high_est') + ' ' + t('required') + '!')
                      .moreThan(Yup.ref('lowest'), t('should_be_greater_than_the_lowest_price'))
                : customize.low_and_high_est
                ? Yup.number()
                      .required(t('high_est') + ' ' + t('required') + '!')
                      .moreThan(Yup.ref('lowest'), t('should_be_greater_than_the_lowest_price'))
                : Yup.number().when('auction', {
                      is: true,
                      then: Yup.number().required(t('high_est') + ' ' + t('required') + '!'),
                  }),
        tags: Yup.array(),
        // enddate: Yup.string().required('Required!').nullable(),
        lotof: Yup.string(),
        paymentoptionresult: Yup.string(),
        contenthead1: Yup.string(),
        contenthead2: Yup.string(),
        // contenthead3: Yup.string(),
        // contenthead4: Yup.string(),
        // contenthead5: Yup.string().required('Required!'),
        hasshipping: Yup.boolean(),
        sell_location: Yup.number('Numbers only!').when('hasshipping', {
            is: true,
            then: Yup.number('Numbers only!').when('remove_shipping_fields', {
                is: 1,
                then: Yup.number('Numbers only!')
                    .positive(t('must_be_postive'))
                    .required('Pallet Count Required!'),
            }),
        }),
        city: Yup.string()
            .trim()
            .matches(/^[a-zA-Z ]*$/g, 'Only characters allowed'),
        // zipcode: Yup.string().when('location', {
        //     is: '38',
        //     then: Yup.string()
        //         .required(t('required') + '!')
        //         .max(6, 'Invalid postal code'),
        //     otherwise: Yup.string()
        //         .required(t('required') + '!')
        //         .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
        //         .max(6, 'Invalid zip code'),
        // }),
        weight: Yup.number('Numbers only!').when('hasshipping', {
            is: true,
            then: Yup.number('Numbers only!').when('remove_shipping_fields', {
                is: 0,
                then:
                    global.storeDetails?.theme != 14
                        ? Yup.number('Numbers only!')
                              .positive(t('must_be_postive'))
                              .required('Weight Required!')
                        : Yup.number().test(
                              'maxDigits',
                              'Value must have 2 digits or less',
                              (number) => String(number).length <= 2,
                          ),
            }),
        }),
        length: Yup.number('Numbers only!').when('hasshipping', {
            is: true,
            then: Yup.number('Numbers only!').when('remove_shipping_fields', {
                is: 0,
                then:
                    global.storeDetails?.theme != 14
                        ? Yup.number('Numbers only!')
                              .positive(t('must_be_postive'))
                              .required('Length Required!')
                        : Yup.number().test(
                              'maxDigits',
                              'Value must have 2 digits or less',
                              (number) => String(number).length <= 2,
                          ),
            }),
        }),
        width: Yup.number('Numbers only!').when('hasshipping', {
            is: true,
            then: Yup.number('Numbers only!').when('remove_shipping_fields', {
                is: 0,
                then:
                    global.storeDetails?.theme != 14
                        ? Yup.number('Numbers only!')
                              .positive(t('must_be_postive'))
                              .required('Width Required!')
                        : Yup.number().test(
                              'maxDigits',
                              'Value must have 2 digits or less',
                              (number) => String(number).length <= 2,
                          ),
            }),
        }),
        height: Yup.number('Numbers only!').when('hasshipping', {
            is: true,
            then: Yup.number('Numbers only!').when('remove_shipping_fields', {
                is: 0,
                then:
                    global.storeDetails?.theme != 14
                        ? Yup.number('Numbers only!')
                              .positive(t('must_be_postive'))
                              .required('Height Required!')
                        : Yup.number().test(
                              'maxDigits',
                              'Value must have 2 digits or less',
                              (number) => String(number).length <= 2,
                          ),
            }),
        }),
        is_digital: Yup.number(),
        shippingcost: Yup.number('Numbers only!').when('is_digital', {
            is: 1,
            then: Yup.number().positive(t('must_be_postive')).lessThan(101, 'Minimum % is 100'),
            otherwise: Yup.number('Numbers only!').when('freeShipping', {
                is: false,
                then: Yup.number().min(0, t('must_be_postive')),
            }),
        }),
        uploadimages: Yup.array(),
        product_deletedimage: Yup.array(),
        product_image: Yup.array(),
        product_existingimage: Yup.array(),
        product_imagefile: Yup.array(),
        artist_name: customize.artauctionfields
            ? Yup.string().required(t('artist_name') + ' ' + t('required') + '!')
            : '',

        m_athelet:
            customize.need_more_details && value == 2
                ? Yup.string().matches(
                      /^[a-zA-Z\s]*$/g,
                      'The special characters and numbers are not allowed!',
                  )
                : '',
        m_team:
            customize.need_more_details && value == 2
                ? Yup.string().matches(
                      /^[a-zA-Z\s]*$/g,
                      'The special characters and numbers are not allowed!',
                  )
                : '',
        m_brand:
            customize.need_more_details && value == 2
                ? Yup.string().matches(
                      /^[a-zA-Z\s]*$/g,
                      'The special characters and numbers are not allowed!',
                  )
                : '',
        m_year:
            customize.need_more_details && value == 2
                ? Yup.string().min(4, 'Invalid Year').max(4, 'Invalid Year')
                : '',

        start_date: props.end_date_required
            ? Yup.string()
                  .nullable()
                  .when('auction', {
                      is: true,
                      then: Yup.string().required('Incorrect Start Date').nullable(),
                  })
            : '',

        end_date: props.end_date_required
            ? Yup.string()
                  .nullable()
                  .when('auction', {
                      is: true,
                      then: Yup.string().required('Incorrect End Date').nullable(),
                  })
            : '',

        // end_date: Yup.string()
        //     .nullable()
        //     .when('auction', {
        //         is: true,
        //         then: Yup.string().required('Please Select End Date').nullable(),
        //     }),
    })

    const formik = useFormik({
        initialValues: {
            product_id: '',
            auctionid: '',
            category_id: '',
            radcondition: '',
            subcategory: '',
            protitle: '',
            auction: type === 'auction',
            buynow: type === 'buynow',
            sprice: global.storeConfigration?.start_price_global?.value
                ? global.storeConfigration?.start_price_global?.value
                : '',
            rprice: '',
            enable_rprice: 0,
            selectedbuyers: [],
            prev_rprice: 0,
            bprice: '',
            startdate: '',
            description: '',
            qty: global.storeConfigration?.global_quantity?.value
                ? global.storeConfigration?.global_quantity?.value
                : 1,
            tags: [],
            enddate: '',
            state: '',
            location: '',
            city: '',
            lotof: auction_id,
            paymentoptionresult: '',
            contenthead1: 0,
            contenthead2: global.products_detail,
            contenthead3: global.products_terms,
            contenthead4: '',
            contenthead5: [],
            latestbid: 0,
            hasshipping: customize.externalshipingOnly ? 1 : 0,
            localpickup: customize.externalshipingOnly ? 0 : 1,
            sell_location: '',
            weight: 0,
            length: 0,
            width: 0,
            height: 0,
            uploadimages: [],
            product_deletedimage: [],
            product_deletedvideos: [],
            product_image: [],
            sep_images: [],
            product_sep_existingimage: [],
            product_sep_deletedimage: [],
            product_videos: [],
            product_existingimage: [],
            product_existingvideos: [],
            product_imagefile: [],
            shippingcost: 0,
            is_digital: 0,
            market_status: '',
            sku: '',
            lowest: '',
            highest: '',
            inventory_loc: '',
            address: '',
            zipcode: '',
            start_date: customize.set_start_and_enddate ? dateTimeFormatFunction(new Date()) : null,
            end_date: null,
            customfield4: '',
            customfield5: '',
            customfield6: '',
            customfield7: '',
            customfield9: '',
            work_loc: [],
            storage: '',
            package_date: null,
            selling_unit: '',
            total_weight: '',

            buyerpremium_percentage:
                props.need_buyerpremium == 0 &&
                global.storeConfigration?.global_buyer_premium?.value
                    ? global.storeConfigration.global_buyer_premium.value
                    : global.storeConfigration?.buyer_premium_percentage?.value,
            remove_shipping_fields:
                global.storeConfigration?.remove_shipping_fields?.value == 1 ? 1 : 0,
            sustainability: [],
            qcedby: props.taxFree ? 1 : 0,
            addressSection: customize.addressSection,
            categoryNotRequired: customize.categoryNotRequired === 1 ? 1 : 0,
            saletype: 'auction_io',
            store_id: 0,
            extbidding: global.storeConfigration?.pickup_details_global?.value
                ? global.storeConfigration?.pickup_details_global?.value
                : '',
            product_existing_image_0: [],
            product_existing_image_1: [],
            product_existing_image_2: [],
            product_existing_image_3: [],
            product_existing_image_4: [],
            product_deleted_image_0: [],
            product_deleted_image_1: [],
            product_deleted_image_2: [],
            product_deleted_image_3: [],
            product_deleted_image_4: [],
            artist_name: '',

            m_year: '',
            m_brand: '',
            m_autographed: '',
            m_graderating: '',
            m_athelet: '',
            m_team: '',
            verification_doc: '',
            start_date_disable: false,
            end_date_disable: false,
            no_need_more_details: false,
            freeShipping: customize.freeShipping,
            make_an_offer_io: 0,
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            if (
                values.localpickup === true ||
                values.hasshipping === true ||
                values.freeShipping === true ||
                values.localpickup === 1 ||
                values.hasshipping === 1 ||
                values.freeShipping === 1
            ) {
                if (
                    customize.need_more_details == 1 &&
                    value == 0 &&
                    !formik.values.no_need_more_details
                ) {
                    setValue(2)
                    return false
                }

                let formValues = values
                formValues.buynow = formValues.buynow ? 1 : 0
                formValues.auction = formValues.auction ? 1 : 0
                formValues.hasshipping = formValues.hasshipping ? 1 : 0
                formValues.localpickup = formValues.localpickup ? 1 : 0
                formValues.is_digital = parseInt(formValues.is_digital)
                if (customize.artauctionfields) {
                    formValues.contenthead5 = formValues.artist_name
                } else {
                    formValues.contenthead5 = formValues.sustainability.join(',')
                }
                if (customize.withoutAuction) {
                    formValues.startdate = formValues.start_date
                    formValues.enddate = formValues.end_date
                } else {
                    formValues.startdate = edit_auction.auction_details.date_added
                    formValues.enddate = edit_auction.auction_details.date_closed
                }

                if (customize.need_more_details == 1) {
                    formValues.extra_fields = JSON.stringify({
                        m_year: formValues.m_year,
                        m_brand: formValues.m_brand,
                        m_autographed: formValues.m_autographed,
                        m_graderating: formValues.m_graderating,
                        m_athelet: formValues.m_athelet,
                        m_team: formValues.m_team,
                    })
                    if (product_id && !repost && formik.values.verification_doc.length > 0) {
                        if (formValues.work_loc === formik.values.verification_doc[0]) {
                            formik.values.existing_work_loc = formik.values.verification_doc[0]
                        } else {
                            formik.values.work_loc = formik.values.verification_doc
                        }
                    } else {
                        formik.values.work_loc = formik.values.verification_doc
                    }
                }

                if (customize.multiSectionImage?.length > 0) {
                    let tempArray = [],
                        ext_arr = [],
                        pos_img_new = [],
                        deleted_img = []
                    customize.multiSectionImage?.map((data, index) => {
                        if (formValues[`product_image_${index}`] != '') {
                            tempArray.push(formValues[`product_image_${index}`])
                            pos_img_new.push(index)
                        }
                        if (
                            formValues[`product_existing_image_${index}`][0] != undefined &&
                            formValues[`product_existing_image_${index}`][0] != ''
                        ) {
                            ext_arr.push(formValues[`product_existing_image_${index}`][0])
                        }
                        if (
                            formValues[`product_deleted_image_${index}`][0] != undefined &&
                            formValues[`product_deleted_image_${index}`][0] != ''
                        ) {
                            deleted_img.push(formValues[`product_deleted_image_${index}`][0])
                        }
                    })
                    formValues['sep_images'].map((val, index) => {
                        pos_img_new.push(parseInt(index) + 4)
                    })
                    formValues['product_sep_existingimage'].map((val, index) => {
                        pos_img_new.push(parseInt(index) + 4)
                    })
                    tempArray = [...tempArray, ...formValues['sep_images']]
                    formValues.product_image = tempArray
                    formik.values.product_existingimage = [
                        ...ext_arr,
                        ...formValues['product_sep_existingimage'],
                    ]
                    formValues.product_existingimage = [
                        ...ext_arr,
                        ...formValues['product_sep_existingimage'],
                    ]
                    formValues.product_deletedimage = [
                        ...deleted_img,
                        ...formValues['product_sep_deletedimage'],
                    ]
                    formValues.img_pos_new = pos_img_new
                }

                formValues.rprice = formValues.enable_rprice ? formValues.rprice : 0
                formValues.customfield8 = JSON.stringify({
                    address: formValues.address,
                    zipcode: formValues.zipcode,
                })
                if (auctionDetails.address) {
                    formValues.customfield8 = JSON.stringify({
                        address: auctionDetails.address,
                        zipcode: auctionDetails.zipcode,
                    })
                }
                formValues.product_existing_videos = formValues.product_existingvideos.join(',')
                formValues.customfield9 = JSON.stringify({
                    storage: formValues.storage,
                    package_date: formValues.package_date,
                    selling_unit: formValues.selling_unit,
                    total_weight: formValues.total_weight,
                })
                formValues.buyerpremium_percentage = auctionDetails.la_seller_id
                    ? auctionDetails.la_seller_id
                    : formValues.buyerpremium_percentage
                if (!formik.values.auction) {
                    if (
                        formik.values.buynow === 1 &&
                        formik.values.auction === 0 &&
                        global.storeConfigration?.enable_buynow_to_auction?.value == 1
                    ) {
                        var current_date = new Date()
                        formValues.startdate = new Date()
                        formValues.enddate = new Date(
                            current_date.setDate(
                                current_date.getDate() +
                                    parseInt(
                                        global.storeConfigration?.buynow_to_auction_period?.value,
                                    ),
                            ),
                        )
                    } else {
                        var current_date = new Date()
                        formValues.startdate = new Date()
                        formValues.enddate = new Date(
                            current_date.setFullYear(current_date.getFullYear() + 4),
                        )
                    }

                    formValues.no_cron = 1
                }
                if (formik.values.make_an_offer_io) {
                    formValues.make_an_offer_io = 1
                } else {
                    formValues.make_an_offer_io = 0
                }
                if (customize.change_date_time == 1) {
                    formValues.startdate = new Date(formValues.start_date).toString()
                    formValues.enddate = new Date(formValues.end_date).toString()
                }
                if (customize.low_est_field_as_buynow == 1) {
                    formValues.bprice = formValues.lowest
                    formValues.buynow = 1
                }
                let formDataSend = jsonToFormData(formValues)
                if (product_id && !repost) {
                    editProduct(formDataSend)
                } else {
                    postProduct(formDataSend)
                }
                setLoading(true)
            } else {
                setAlert('Please select local pickup or shipping', 'error')
            }
        },
    })
    useEffect(() => {
        if (loading && (global?.storeDetails.theme === 7 || global?.storeDetails.theme === 3)) {
            setAlert(
                'Please Wait While We optimie your item(s) listing.Once this message disappears you may add the next item(s)',
                'warnning',
            )
        }
    }, [loading])
    useEffect(() => {
        if (type) {
            formik.setFieldValue('buynow', type === 'buynow')
            formik.setFieldValue('auction', type === 'auction')
        }
    }, [window.location.search])

    useEffect(() => {
        setCountrycode(formik.values.country)
    }, [formik.values.country])
    useEffect(() => {
        if (!formik.values.buynow) {
            formik.setFieldValue('make_an_offer_io', 0)
        }
    }, [formik.values.buynow])

    useEffect(() => {
        if (responseStatus) {
            if (
                responseStatus.from === 'postProduct' ||
                responseStatus.from === 'editProduct' ||
                responseStatus.from === 'bulkUploadInventory'
            ) {
                setLoading(false)

                if (responseStatus.status === 'success') {
                    if (posting_without_onboard) {
                        if (
                            !user?.card_profileid ||
                            (global.pluginConfiguration?.reseller_subscription?.enable &&
                                !user?.subscribed)
                        ) {
                            if (
                                global.pluginConfiguration?.reseller_subscription?.enable &&
                                !user?.subscribed
                            ) {
                                setAlert('Kindly subscribe for move to product in live!', 'warning')
                                return handleRedirectInternal(history, 'dashboard/subscription')
                            }
                            // else {
                            //     setAlert(
                            //         'Kindly add account id for move to product in live!',
                            //         'warning',
                            //     )
                            //     return handleRedirectInternal(
                            //         history,
                            //         'dashboard/profile?moveto="profilepage"',
                            //     )
                            // }
                        }
                    }
                    if (
                        global.pluginConfiguration?.reseller_listing_fee?.enable == 1 &&
                        (!product_id || repost)
                    ) {
                        return setListingFeePopup(responseStatus.data)
                    }
                    if (customize.withoutAuction) {
                        if (addMore) {
                            if (addMore == 4) {
                                setValue(0)
                            }
                            formik.resetForm()
                            bulkFormik.resetForm()
                            window.scrollTo(0, 0)
                        } else {
                            handleRedirectInternal(
                                history,
                                props.redirectRoute ? props.redirectRoute : 'dashboard/mylots',
                            )
                        }
                        return true
                    }
                    setLoading(true)
                    var sendData = auctionDetails
                    sendData.lot_id = [...sendData.lot_id, responseStatus.data, product_id]
                    let formDataSend = jsonToFormData(sendData)
                    postAuction(formDataSend, 1)
                    var items_limit = global.storeConfigration?.global_lot_items?.value
                        ? global.storeConfigration?.global_lot_items?.value
                        : 0
                    if (parseInt(items_limit) > 0) {
                        item_count = item_count + 1
                        if (parseInt(items_limit) <= parseInt(item_count)) {
                            setAlert('Your Lot Limit Is Reached!', 'error')
                            handleRedirectInternal(history, `dashboard/overview`)
                        }
                    }
                }
            }
        }
    }, [responseStatus])
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'postAuction') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    if (addMore) {
                        formik.resetForm()
                        bulkFormik.resetForm()
                        window.scrollTo(0, 0)
                    } else {
                        handleRedirectInternal(history, `${'sellerdashboard?page=' + 0}`)
                    }
                }
                clearResponse()
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (isAuthenticated) {
            if (product_id) {
                // setLoading(true)
                getEditProduct({ product_id })
            }
            getOverAllCategories({}, global?.storeConfigration?.cat_mis_match?.value)
            if (global.storeConfigration?.add_extra_fillter?.value == 1) {
                getAllbrands({ site_id: global?.storeDetails?.site_id })
            }
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (edit_product.product_details && product_id) {
            let details = edit_product.product_details
            formik.values.product_id = details.id ? details.id : ''
            formik.values.auctionid = details.auctionid ? details.auctionid : ''
            formik.values.category_id = details.category_id ? details.category_id : ''
            formik.values.radcondition = details.conditionunique ? details.conditionunique : ''
            formik.values.subcategory = details.subcategory ? details.subcategory : ''
            formik.values.protitle = details.title ? details.title : ''
            formik.values.latestbid = details.latestbid ? details.latestbid : ''
            formik.values.location = details.location ? details.location : ''
            formik.values.state = details.state ? details.state : ''
            formik.values.city = details.city ? details.city : ''
            formik.values.tags = details.tags ? details.tags.split(',') : []
            formik.values.lotof = details.lotof ? details.lotof : ''
            formik.values.auction = details.auction ? true : false
            formik.values.buynow = details.buynow ? true : false
            formik.values.sprice = details.sprice ? details.sprice : ''
            formik.values.rprice = details.rprice ? details.rprice : ''
            formik.values.enable_rprice = details.rprice && parseInt(details.rprice) > 0 ? 1 : 0
            formik.values.selectedbuyers = details.selectedbuyers
                ? details.selectedbuyers.split(',').map((i) => parseInt(i))
                : []
            formik.values.prev_rprice = details.rprice ? details.rprice : ''
            formik.values.bprice = details.bprice && details.buynow ? details.bprice : ''
            formik.values.startdate = details.date_added
                ? dateTimeFormatFunction(details.date_added, true)
                : dateTimeFormatFunction(new Date())
            if (repost && customize.change_date_time != 1) {
                formik.values.start_date = dateTimeFormatFunction(new Date())
            } else {
                formik.values.start_date = details.date_added
                formik.values.end_date = details.date_closed
            }
            var address = JSON.parse(details.custom_field_8 ? details.custom_field_8 : '{}')
            formik.values.address = address.address
            formik.values.zipcode = address.zipcode
            var foodIndustryFields = JSON.parse(
                details.custom_field_9 ? details.custom_field_9 : '{}',
            )
            formik.values.storage = foodIndustryFields.storage
            formik.values.package_date = foodIndustryFields.package_date
            formik.values.selling_unit = foodIndustryFields.selling_unit
            formik.values.total_weight = foodIndustryFields.total_weight
            formik.values.qty = details.qty ? details.qty : 1
            formik.values.customfield4 = details.custom_field_4
            formik.values.customfield5 = details.custom_field_5
            formik.values.customfield6 = details.custom_field_6
            formik.values.customfield7 = details.custom_field_7
            formik.values.buyerpremium_percentage = details.buyerpremium_percentage
                ? details.buyerpremium_percentage
                : 0
            formik.values.enddate = details.date_closed
                ? dateTimeFormatFunction(details.date_closed, true)
                : dateTimeFormatFunction(new Date())
            if (!repost) {
                if (details.videos) {
                    formik.values.product_existingvideos = details.videos.split(',')
                }

                if (customize.multiSectionImage?.length > 0) {
                    formik.values.product_sep_existingimage = []
                    formik.values.product_existing_image_0 = []
                    formik.values.product_existing_image_1 = []
                    formik.values.product_existing_image_2 = []
                    formik.values.product_existing_image_3 = []
                    product_existing_image_4: [],
                        edit_product.attachments.map((data, index) => {
                            if (data.orderby < 4) {
                                formik.values[
                                    `product_existing_image_${data.orderby ? data.orderby : index}`
                                ] = [data.src]
                            } else {
                                formik.values.product_sep_existingimage.push(data.src)
                            }
                        })
                } else {
                    formik.values.product_existingimage = edit_product.attachments.map((i) => i.src)
                }
            }
            formik.values.paymentoptionresult = details.paymentoptionresult
                ? details.paymentoptionresult
                : ''
            formik.values.market_status = details.market_status
            formik.values.contenthead1 = details.content_head1 ? details.content_head1 : 0
            formik.values.contenthead2 = details.content_head2
                ? details.content_head2
                : global.products_detail
            formik.values.contenthead3 = details.content_head3
                ? details.content_head3
                : global.products_terms
            formik.values.hasshipping = details.hasshipping ? 1 : 0
            formik.values.sell_location = details.sell_location ? details.sell_location : 0
            formik.values.qcedby = details.qcdby == 1 || details.qcdby == 'true' ? 1 : 0
            formik.values.sku = details.sku ? details.sku : ''
            formik.values.description = details.description ? details.description : ''
            formik.values.weight = details.weight ? details.weight : 0
            formik.values.length = details.length ? details.length : 0
            formik.values.width = details.width ? details.width : 0
            formik.values.subcategory = details.subcategory ? details.subcategory : ''
            formik.values.height = details.height ? details.height : 0
            formik.values.contenthead4 = details.content_head4 ? details.content_head4 : ''
            if (customize.artauctionfields) {
                formik.values.artist_name = details.content_head5 ? details.content_head5 : ''
            } else {
                formik.values.sustainability = details.content_head5.split(',').map(Number)
                    ? details.content_head5.split(',').map(Number)
                    : []
            }
            formik.values.product_deletedimage = []
            formik.values.product_deletedvideos = []
            formik.values.shippingcost = details.shippingcost
            formik.values.is_digital = details.is_digital ? details.is_digital : 0
            formik.values.inventory_loc = details.inventory_loc ? details.inventory_loc : ''
            formik.values.extbidding = details.extbidding ? details.extbidding : ''
            formik.values.lowest = details.lowest ? details.lowest : 0
            formik.values.highest = details.lowest ? details.highest : 0
            formik.values.make_an_offer_io = details.make_an_offer_io ? details.make_an_offer_io : 0
            if (customize.freeShipping) {
                formik.values.freeShipping = parseInt(details.shippingcost) > 0 ? 0 : 1
            }

            if (customize.need_more_details == 1) {
                var extra_fields = details.extra_fields ? JSON.parse(details.extra_fields) : ''
                formik.values.m_year = extra_fields.m_year ? extra_fields.m_year : ''
                formik.values.m_athelet = extra_fields.m_athelet ? extra_fields.m_athelet : ''
                formik.values.m_autographed = extra_fields.m_autographed
                    ? extra_fields.m_autographed
                    : ''
                formik.values.m_brand = extra_fields.m_brand ? extra_fields.m_brand : ''
                formik.values.m_graderating = extra_fields.m_graderating
                    ? extra_fields.m_graderating
                    : ''
                formik.values.m_team = extra_fields.m_team ? extra_fields.m_team : ''
                ////console.log('pppppppppppppppppppppppppppppppppp1', extra_fields.work_loc)
                formik.values.work_loc =
                    product_id && !repost && details.work_loc ? details.work_loc : ''
                formik.values.verification_doc =
                    product_id && !repost && details.work_loc ? [details.work_loc] : ''
            }

            if (customize.need_to_disable_start_and_endate && !repost) {
                if (formik.values.market_status == 'open' && details.created_at) {
                    if (
                        moment
                            .duration(moment(new Date()).diff(moment(details.created_at)))
                            .asDays() > 14
                    ) {
                        formik.values.start_date_disable = true
                    }
                    if (
                        moment
                            .duration(moment(new Date()).diff(moment(details.created_at)))
                            .asDays() > 5
                    ) {
                        formik.values.end_date_disable = true
                    }
                }
            }

            if (customize.change_date_time == 1) {
                formik.values.start_date = moment(
                    formik.values.start_date,
                    moment.defaultFormat,
                ).toDate()
                formik.values.end_date = moment(
                    formik.values.end_date,
                    moment.defaultFormat,
                ).toDate()
            }

            setLoading(false)
            setReload(!reload)
            setAlldata(true)
        }
    }, [edit_product.product_details])
    useEffect(() => {
        if (formik.values.category_id) {
            setSubCategory(categories.find((val) => val.id == formik.values.category_id)?.filters)
        }
    }, [formik.values.category_id])
    const getLocationData = () => {
        var body = {
            countryCode: formik.values.location,
        }

        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setStateList(response.data.state)
                    setCountryList(response.data.country)
                }
            })
            .catch(function (error) {
                //console.log(error)
            })
    }

    useEffect(() => {
        getLocationData()
    }, [formik.values.location])
    // //console.log('props', props)
    /* added below condition for set enddate to defauct from startdate+store seller give input from configuration */
    useEffect(() => {
        if (
            global.storeConfigration?.end_date_hours_from_start_date?.value &&
            formik.values.start_date &&
            (!product_id || repost)
        ) {
            var auto_end_date = new Date(formik.values.start_date)
            formik.values.end_date = auto_end_date.setHours(
                auto_end_date.getHours() +
                    parseInt(global.storeConfigration?.end_date_hours_from_start_date?.value),
            )
            formik.values.end_date = new Date(formik.values.end_date)
        }
    }, [formik.values.start_date])
    var productListing = [
        {
            label: t('lot_title') + ' *',
            type: 'text',
            placeholder: t('enter_lot_title'),
            class: 'col-12',
            name: 'protitle',
            formik: formik,
        },
        {
            label: t('category') + ' *',
            placeholder: 'Select category',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: categories.map((val) => ({
                show: val.name,
                value: val.id,
            })),
            defaultOptionValue: 'Select a Category',
            name: 'category_id',
            formik: formik,
        },
        {
            label: t('sub_category'),
            placeholder: 'Select sub-category',
            type: 'select',
            class: `col-sm-6 col-12 ${customize.hideSubCategory === 1 ? 'd-none' : ''} ${
                !subCategory?.length && formik.values.category_id ? 'd-none' : ''
            }`,
            options: subCategory?.map((val) => ({
                show: val.name,
                value: val.id,
            })),
            defaultOptionValue: 'Select a Sub Category',
            name: 'subcategory',
            formik: formik,
        },
        {
            label: props.foodIndustryFields ? (
                <>
                    Description *{' '}
                    <small style={{ color: 'gray' }}>
                        Tell prospective buyers about your product here including:
                    </small>
                </>
            ) : (
                t('description') + ' *'
            ),
            placeholder:
                'Product Name, Origin Location, Quality, Storage requirements, Weight, Quantity',
            type: 'ckeditor1',
            class: 'col-12',
            name: 'description',
            formik: formik,
        },
        {
            label:
                global.storeConfigration?.condition_not_required?.value == '1'
                    ? t('condition')
                    : t('condition') + ' *',
            placeholder: 'Select condition',
            type: 'select',
            class:
                global.storeConfigration?.add_extra_fillter?.value == 1
                    ? 'd-none'
                    : global.storeConfigration?.global_quantity?.value == 1
                    ? 'col-12'
                    : 'col-sm-6 col-12',
            options:
                customize.customCondition && customize.customCondition.length > 0
                    ? customize.customCondition
                          .filter(
                              (maincat) =>
                                  maincat.active === 1 ||
                                  (formik.values.conditionTypeId &&
                                      formik.values.conditionTypeId === maincat.id),
                          )
                          .map((location) => {
                              let conditionChanged = {}
                              conditionChanged.show = location.description
                              conditionChanged.value = location.id
                              return conditionChanged
                          })
                    : allCondition
                          .filter(
                              (maincat) =>
                                  maincat.active === 1 ||
                                  (formik.values.conditionTypeId &&
                                      formik.values.conditionTypeId === maincat.id),
                          )
                          .map((location) => {
                              let conditionChanged = {}
                              conditionChanged.show = location.description
                              conditionChanged.value = location.id
                              return conditionChanged
                          }),
            defaultOptionValue: 'Select a Condition',
            name: 'radcondition',
            formik: formik,
        },
        {
            label: 'Brands' + ' *',
            placeholder: 'Select Brands',
            type: 'select',
            class: global.storeConfigration?.add_extra_fillter?.value == 1 ? 'col-12' : 'd-none',
            options: brandsproduct.map((location) => {
                let conditionChanged = {}
                conditionChanged.show = location.description
                conditionChanged.value = location.id
                return conditionChanged
            }),
            defaultOptionValue: 'Select a Brand',
            name: 'radcondition',
            formik: formik,
        },
    ]

    //console.log('allCondition', allCondition)
    if (customize.pickupDetails) {
        productListing.push({
            label:
                global?.storeDetails.theme === 11 || customize.shipLabel == 1
                    ? t('ship_details')
                    : 'Pickup Details',
            placeholder: 'Enter pickup details',
            type: 'ckeditor1',
            class: 'col-12',
            name: 'extbidding',
            formik: formik,
        })
    }
    if (customize.itemLocation) {
        productListing.push({
            label: 'Inventory Location',
            type: 'text',
            placeholder: 'Enter your Inventory Location',
            class: 'col-sm-6 col-12',
            name: 'inventory_loc',
            formik: formik,
        })
    }

    if (customize.foodIndustryFields) {
        productListing.push({
            label: 'Sustainability',
            placeholder: 'Select sustainability',
            type: 'multiselect',
            class: 'col-sm-6 col-12',
            options: sustainabilityOptions
                .filter((maincat) => maincat.active === 1)
                .map((location) => {
                    let conditionChanged = {}
                    conditionChanged.show = location.description
                    conditionChanged.value = location.id
                    return conditionChanged
                }),
            defaultOptionValue: 'Select sustainability',
            name: 'sustainability',
            formik: formik,
        })
    }
    if (customize.liquidationPalletFields) {
        productListing.push({
            label: 'Seller',
            placeholder: 'Select seller',
            type: 'multiselect',
            class: 'col-sm-6 col-12',
            options: brandsliqutatios
                .filter((maincat) => maincat.active === 1)
                .map((location) => {
                    let conditionChanged = {}
                    conditionChanged.show = location.description
                    conditionChanged.value = location.id
                    return conditionChanged
                }),
            defaultOptionValue: 'Select sustainability',
            name: 'sustainability',
            formik: formik,
        })
    }
    if (customize.artauctionfields) {
        productListing.push({
            label: t('artist_name'),
            type: 'text',
            placeholder: t('enter_artist'),
            class: 'col-sm-6 col-12',
            name: 'artist_name',
            formik: formik,
        })
    }
    if (
        global.storeConfigration?.global_quantity?.value == 0 ||
        global.storeConfigration?.global_quantity == undefined
    ) {
        productListing.push({
            label: t('quantity') + ' *',
            type: 'number',
            placeholder: 'Enter Quantity',
            class: customize.foodIndustryFields ? 'col-sm-2 col-6' : 'col-sm-6 col-12',
            name: 'qty',
            formik: formik,
        })
    }
    if (customize.foodIndustryFields) {
        productListing.push(
            {
                label: 'Selling Unit *',
                placeholder: 'Select unit',
                type: 'select',
                class: 'col-sm-4 col-6',
                options: unitOptions.map((location) => {
                    let conditionChanged = {}
                    conditionChanged.show = location.description
                    conditionChanged.value = location.id
                    return conditionChanged
                }),
                defaultOptionValue: 'Select unit',
                name: 'selling_unit',
                formik: formik,
            },
            {
                label: 'Total Weight *',
                type: 'text',
                placeholder: 'Total Weight',
                class: 'col-sm-6 col-12',
                name: 'total_weight',
                formik: formik,
                endAdornment: 'Kg',
            },
        )
    }
    if (customize.foodIndustryFields) {
        productListing.push(
            {
                label: 'Product Storage *',
                placeholder: 'Select storage',
                type: 'select',
                class: 'col-sm-6 col-12',
                options: storageOptions.map((location) => {
                    let conditionChanged = {}
                    conditionChanged.show = location.description
                    conditionChanged.value = location.id
                    return conditionChanged
                }),
                defaultOptionValue: 'Select storage',
                name: 'storage',
                formik: formik,
            },
            {
                label: 'Product Package Date *',
                placeholder: 'DD/MM/YYYY',
                type: 'date',
                // disablePast: true,
                class: 'col-sm-6 col-12',
                name: 'package_date',
                formik: formik,
                // utc: true,
            },
        )
    }
    if (customize.addressSection) {
        if (props.defaultCountryCode === undefined || props.defaultCountryCode == '') {
            productListing.push(
                {
                    label:
                        customize.customLabel === 1
                            ? 'Seller Address*'
                            : props.theme === 'FoodIndustry'
                            ? t('product_address') + '*'
                            : 'Address*',
                    placeholder:
                        props.theme === 'FoodIndustry'
                            ? t('product_address') + '*'
                            : 'Enter Address',
                    type: 'textarea',
                    class: 'col-12',
                    name: 'address',
                    formik: formik,
                },
                {
                    label: customize.customLabel === 1 ? 'Seller Country*' : t('country') + '*',
                    placeholder: 'Select your country',
                    class: 'col-12 col-sm-6',
                    type: 'select',
                    options: swapCountry(countryList).map((country) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = country.name
                        busCategoryChanged.value = country.location_id
                        return busCategoryChanged
                    }),
                    name: 'location',
                    onChange: (e) => {
                        formik.handleChange(e)
                        formik.setFieldValue('state', '')
                    },
                },
                {
                    label: customize.customLabel === 1 ? 'Seller State*' : t('state') + '*',
                    placeholder: 'Select your state',
                    class: 'col-12 col-sm-6',
                    type: 'select',
                    options: stateList.map((buscat) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = buscat.name
                        busCategoryChanged.value = buscat.name
                        return busCategoryChanged
                    }),
                    name: 'state',
                },
                {
                    label: customize.customLabel === 1 ? 'Seller City*' : t('city') + '*',
                    type: 'text',
                    placeholder: t('enter_city'),
                    class: 'col-sm-6 col-12',
                    name: 'city',
                    formik: formik,
                },
                {
                    label:
                        props.defaultCountryCode == '38'
                            ? 'Postal Code' + '*'
                            : customize.customLabel === 1
                            ? 'Seller Zip*'
                            : t('Zip_code') + '*',
                    type: 'text',
                    placeholder: t('enter_zip'),
                    class: `col-12 col-sm-6 ${formik?.values?.country == 156 ? 'd-none' : ''}`,
                    name: 'zipcode',
                    formik: formik,
                },
            )
        }
    }
    if (props.defaultCountryCode == '38') {
        productListing.push(
            {
                label: props.theme === 'FoodIndustry' ? 'Product Location Address*' : 'Address*',
                placeholder:
                    props.theme === 'FoodIndustry'
                        ? 'Enter Product Location Address*'
                        : 'Enter Address',
                type: 'textarea',
                class: 'col-12',
                name: 'address',
                formik: formik,
            },
            {
                label: 'Country' + '*',
                placeholder: 'Select your country',
                class: 'col-12 col-sm-6',
                type: 'select',
                options: countryList
                    .filter((country) => {
                        return country.location_id === 38 || country.location_id === 224
                    })
                    .map((country) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = country.name
                        busCategoryChanged.value = country.location_id
                        return busCategoryChanged
                    }),
                name: 'location',
            },
            {
                label: props.defaultCountryCode == '38' ? 'Province' + '*' : 'State' + '*',
                placeholder:
                    props.defaultCountryCode == '38' ? 'Select your province' : 'Select your state',
                class: 'col-12 col-sm-6',
                type: 'select',
                options: stateList.map((buscat) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.show = buscat.name
                    busCategoryChanged.value = buscat.name
                    return busCategoryChanged
                }),
                name: 'state',
            },
            {
                label: 'City *',
                type: 'text',
                placeholder: t('enter_city'),
                class: 'col-sm-6 col-12',
                name: 'city',
                formik: formik,
            },
            {
                label: props.defaultCountryCode == '38' ? 'Postal Code' + '*' : 'Zip code' + '*',
                type: 'text',
                placeholder:
                    props.defaultCountryCode == '38' ? 'Enter your postal code' : t('enter_zip'),
                class: `col-sm-6 col-12 ${formik?.values?.country == 156 ? 'd-none' : ''}`,
                name: 'zipcode',
                formik: formik,
            },
        )
    }

    if (customize.buynow) {
        productListing.push(
            {
                type: 'misc',
                content: <Divider className="ppDivider"></Divider>,
                class: `col-12 ${customize.removeAuctionSection ? 'd-none' : ''}`,
            },
            {
                label: 'Enable Auction',
                type: 'checkbox',
                class: `col-md-3 col-sm-6 col-12 ${customize.removeAuctionSection ? 'd-none' : ''}`,
                name: 'auction',
                formik: formik,
            },
        )
    }

    productListing.push(
        {
            label: t('enable_reserve'),
            type: 'checkbox',
            class: `col-md-4 col-sm-6 col-12 ${
                customize.removeAuctionSection ||
                global.storeConfigration?.disable_reserve?.value == 1
                    ? 'd-none'
                    : ''
            }`,
            name: 'enable_rprice',
            formik: formik,
            tooltipTitle:
                'Reserve Price is the lowest amount you are willing to sell this item for.',
            disabled: !formik.values.auction,
        },
        {
            label: t('auction_start_pri'),
            placeholder: t('enter_start'),
            type: 'number',
            class: `${
                global.storeConfigration?.disable_reserve?.value == 1
                    ? 'col-sm-6'
                    : 'col-md-4 col-sm-6'
            } col-12 ${customize.removeAuctionSection ? 'd-none' : ''}`,
            name: 'sprice',
            disabled: formik.values.auction ? false : true,
            formik: formik,
            tooltiptitle:
                global.storeDetails?.theme == 7
                    ? 'Starting item(s) at $2 encourages more bidders to engage with your sale'
                    : '',
        },
        {
            label: t('auction_reserve_p'),
            placeholder: t('enter_reserve'),
            type: 'number',
            class: `col-md-4 col-sm-6 col-12 ${
                customize.removeAuctionSection ||
                global.storeConfigration?.disable_reserve?.value == 1
                    ? 'd-none'
                    : ''
            }`,
            name: 'rprice',
            disabled: formik.values.auction ? false : true,
            disabled: !formik.values.enable_rprice,
            formik: formik,
            // Make the following dynamic later on
            tooltiptitle:
                global.storeDetails?.theme == 7
                    ? 'Placing a reserve on item(s) can discourage buyers.'
                    : '',
        },
        {
            label: t('low_est') + ' *',
            type: 'number',
            placeholder: t('enter_lest'),
            class: `col-sm-6 col-12 ${
                customize.removeAuctionSection ||
                global.storeConfigration?.disable_estimate?.value == 1
                    ? 'd-none'
                    : ''
            }`,
            onChange: (e) => {
                if (parseInt(e.target.value) >= 0 || e.target.value == '') {
                    formik.handleChange(e)
                }
            },
            name: 'lowest',
            formik: formik,
            disabled: !formik.values.auction ? true : false,
            tooltiptitle:
                global.storeDetails?.theme == 7 ? "Please enter '0' if you don't have Low Est" : '',
        },
        {
            label: t('high_est') + ' *',
            type: 'number',
            placeholder: t('enter_hest'),
            class: `col-sm-6 col-12 ${
                customize.removeAuctionSection ||
                global.storeConfigration?.disable_estimate?.value == 1
                    ? 'd-none'
                    : ''
            }`,
            name: 'highest',
            formik: formik,
            disabled: !formik.values.auction ? true : false,
            onChange: (e) => {
                if (parseInt(e.target.value) >= 0 || e.target.value == '') {
                    formik.handleChange(e)
                }
            },
            tooltiptitle:
                global.storeDetails?.theme == 7
                    ? "Please enter '0' if you don't have High Est"
                    : '',
        },
    )
    const max_date_config = (date) => {
        var s_d = new Date(date)
        s_d.setDate(s_d.getDate() + parseInt(global.storeConfigration?.end_date_lot_dif?.value))
        return s_d
    }
    const start_max_date_config = (date, days) => {
        var s_d = new Date(date)
        s_d.setDate(s_d.getDate() + parseInt(days))
        return s_d
    }

    if (customize.withoutAuction) {
        productListing.push(
            {
                label: 'Buyer Premium (%)',
                type: 'number',
                placeholder: 'Enter buyer premium percentage',
                class: `col-sm-6 col-12 ${
                    global.storeConfigration?.buyer_premium_percentage?.value ||
                    global.storeConfigration?.remove_buyers_premium?.value == 1 ||
                    props.need_buyerpremium == 0
                        ? 'd-none'
                        : ''
                }`,
                name: 'buyerpremium_percentage',
                formik: formik,
            },
            {
                label: t('start_date') + ' *',
                placeholder: 'Enter Start date',
                type: customize.change_date_time == 1 ? 'date_text' : 'datetime',
                disablePast: true,
                class: `col-sm-6 col-12 dates ${customize.removeAuctionSection ? 'd-none' : ''}`,
                name: 'start_date',
                formik: formik,
                upperLabel: customize.change_date_time == 1 ? 1 : 0,
                maxDate: customize.set_start_and_enddate
                    ? dateTimeFormatFunction(
                          start_max_date_config(dateTimeFormatFunction(new Date()), 14),
                      )
                    : '',
                // utc: true,
                disabled: !formik.values.auction || formik.values.start_date_disable,
            },
            {
                label: t('end_date') + ' *',
                placeholder: 'Enter End Date',
                disablePast: true,
                type: customize.change_date_time == 1 ? 'date_text' : 'datetime',
                class: `col-sm-6 col-12 dates ${customize.removeAuctionSection ? 'd-none' : ''}`,
                name: 'end_date',
                minDate: customize.set_start_and_enddate
                    ? start_max_date_config(dateTimeFormatFunction(formik.values.start_date), 1)
                    : '',
                upperLabel: customize.change_date_time == 1 ? 1 : 0,
                maxDate:
                    global.storeConfigration?.end_date_lot_dif?.value && formik.values.start_date
                        ? dateTimeFormatFunction(max_date_config(formik.values.start_date))
                        : customize.set_start_and_enddate
                        ? dateTimeFormatFunction(
                              start_max_date_config(
                                  dateTimeFormatFunction(formik.values.start_date),
                                  5,
                              ),
                          )
                        : '',
                // minDateMessage: `Date should not be before minimal date ${dateTimeFormatFunction(
                //     formik.values.date_added,
                // )}`,
                formik: formik,
                // utc: true,
                disabled:
                    !formik.values.auction ||
                    (global.storeConfigration?.end_date_hours_from_start_date?.value &&
                        (!product_id || repost)) ||
                    formik.values.end_date_disable
                        ? true
                        : false,
            },
        )
    }
    productListing.push({
        label: t('sku') + ' *',
        type: 'text',
        placeholder: t('sku'),
        class: customize.skuNotRequired == 1 ? 'd-none' : 'col-sm-6 col-12',
        name: 'sku',
        formik: formik,
        tooltiptitle:
            global.storeDetails?.theme == 7
                ? 'Please enter alpha (or) numeric (or) Combination of both. For example: 01 (or) LotA (or) LOt01'
                : '',
    })

    if (props.taxFree) {
        productListing.push({
            label: 'Tax Free Item',
            type: 'checkbox',
            class: 'col-6',
            name: 'qcedby',
            formik: formik,
        })
    }

    if (customize.buynow) {
        productListing.push(
            {
                type: 'misc',
                content: <Divider className="ppDivider"></Divider>,
                class: `col-12 ${customize.removeAuctionSection ? 'd-none' : ''}`,
            },
            {
                label: 'Enable Buy Now',
                type: 'checkbox',
                class: `col-sm-6 col-12 ${customize.removeAuctionSection ? 'd-none' : ''}`,
                name: 'buynow',
                formik: formik,
            },
            {
                label: 'Enable Make An Offer',
                type: 'checkbox',
                class: `col-sm-6 col-12 ${
                    global.pluginConfiguration?.make_an_offer?.enable ? '' : 'd-none'
                }`,
                name: 'make_an_offer_io',
                formik: formik,
                disabled: formik.values.buynow ? false : true,
            },
            {
                label: 'Buy Now price',
                placeholder: 'Enter buy now price',
                type: 'number',
                class: 'col-sm-6 col-12',
                name: 'bprice',
                disabled: formik.values.buynow ? false : true,
                formik: formik,
            },
        )
    }
    // {
    //     label: 'Terms & conditions',
    //     placeholder: 'Enter terms & conditions',
    //     type: 'ckeditor',
    //     class: 'col-12',
    //     name: 'contenthead3',
    //     formik: formik,
    // },
    // {
    //     label: 'Shipping details',
    //     placeholder: 'Enter shipping details',
    //     type: 'ckeditor',
    //     class: 'col-12',
    //     name: 'contenthead4',
    //     formik: formik,
    // },
    // {
    //     label: 'Shipping',
    //     type: 'checkbox',
    //     class: `col-6`,
    //     name: 'hasshipping',
    //     formik: formik,
    // },
    if (
        global.storeConfigration?.shipping_need?.value == 0 ||
        global.storeConfigration?.shipping_need == undefined
    ) {
        productListing.push(
            {
                type: 'misc',
                content: <Divider className="ppDivider"></Divider>,
                class: 'col-12',
            },
            {
                label: 'Local Pickup',
                type: 'checkbox',
                class: customize.noLocalPickup ? 'd-none' : 'col-md-6 col-12',
                name: 'localpickup',
                formik: formik,
            },
            {
                label: 'Free Shipping',
                type: 'checkbox',
                class: `${customize.noLocalPickup ? 'col-md-12 col-12' : 'col-md-3 col-12'} ${
                    global.storeDetails?.theme == 14 ? '' : 'd-none'
                }`,
                name: 'freeShipping',
                formik: formik,
                // disabled: customize.externalshipingOnly ? true : false,
            },
            global.storeDetails?.theme == 14
                ? {
                      label: t('shipping'),
                      type: 'checkbox',
                      class: `${customize.noLocalPickup ? 'col-md-12 col-12' : 'col-md-6 col-12'} ${
                          formik.values.freeShipping == 1 && global.storeDetails?.theme == 14
                              ? 'd-none'
                              : ''
                      }`,
                      name: 'hasshipping',
                      formik: formik,
                      disabled: false,
                  }
                : {
                      label: t('shipping'),
                      type: 'checkbox',
                      class: `${customize.noLocalPickup ? 'col-md-12 col-12' : 'col-md-6 col-12'} ${
                          formik.values.freeShipping == 1 && global.storeDetails?.theme == 14
                              ? 'd-none'
                              : ''
                      }`,
                      name: 'hasshipping',
                      formik: formik,
                      disabled: customize.externalshipingOnly ? true : false,
                  },
            // {
            //     title: '',
            //     type: 'radio',
            //     noLabel: true,
            //     name: 'hasshipping',
            //     int: 1,
            //     class: 'col-12 mb-3',
            //     item: [
            //         { id: 0, description: 'Local Pickup' },
            //         { id: 1, description: 'Shipping' },
            //     ],
            //     formik: formik,
            // },
            {
                label: 'Pallet Count *',
                type: 'number',
                placeholder: 'Enter pallet count',
                class: `${
                    formik.values.hasshipping == 1 &&
                    global.storeConfigration?.remove_shipping_fields?.value == 1
                        ? ''
                        : 'd-none'
                } ${
                    props.videoUploader ? 'col-md-12 col-sm-6 col-12' : 'col-md-3 col-sm-6 col-12'
                }`,
                name: 'sell_location',
                formik: formik,
            },
            {
                label: 'Shipping Price',
                type: 'number',
                placeholder: 'Enter Price',
                class: `${
                    formik.values.hasshipping == 1 &&
                    formik.values.freeShipping != 1 &&
                    customize.collectablethemefield == 1 &&
                    global.storeConfigration?.remove_shipping_fields?.value != 1
                        ? ''
                        : 'd-none'
                } col-md-12 col-sm-6 col-12`,
                name: 'shippingcost',
                formik: formik,
                min: 0,
            },
            {
                label:
                    global.storeDetails?.theme != 14
                        ? t('weight') + '(' + t('pounds') + ') *'
                        : t('weight') + '(' + t('pounds') + ')',
                type: 'number',
                placeholder: 'Enter Weight',
                class: `${
                    formik.values.hasshipping == 1 &&
                    formik.values.freeShipping != 1 &&
                    global.storeConfigration?.remove_shipping_fields?.value != 1 &&
                    customize.collectablethemefield != 1
                        ? ''
                        : 'd-none'
                } col-md-3 col-sm-6 col-12`,
                name: 'weight',
                formik: formik,
                min: 0,
            },
            {
                label:
                    global.storeDetails?.theme != 14
                        ? t('length') + '(' + t('inches') + ') *'
                        : t('length') + '(' + t('inches') + ')',
                type: 'number',
                placeholder: 'Enter Weight',
                class: `${
                    formik.values.hasshipping == 1 &&
                    formik.values.freeShipping != 1 &&
                    global.storeConfigration?.remove_shipping_fields?.value != 1 &&
                    customize.collectablethemefield != 1
                        ? ''
                        : 'd-none'
                } col-md-3 col-sm-6 col-12`,
                name: 'length',
                formik: formik,
                min: 0,
            },
            {
                label:
                    global.storeDetails?.theme != 14
                        ? t('width') + '(' + t('inches') + ') *'
                        : t('width') + '(' + t('inches') + ')',
                type: 'number',
                placeholder: 'Enter Weight',
                class: `${
                    formik.values.hasshipping == 1 &&
                    formik.values.freeShipping != 1 &&
                    global.storeConfigration?.remove_shipping_fields?.value != 1 &&
                    customize.collectablethemefield != 1
                        ? ''
                        : 'd-none'
                } col-md-3 col-sm-6 col-12`,
                name: 'width',
                formik: formik,
                min: 0,
            },
            {
                label:
                    global.storeDetails?.theme != 14
                        ? t('height') + '(' + t('inches') + ') *'
                        : t('height') + '(' + t('inches') + ')',
                type: 'number',
                placeholder: 'Enter Height',
                class: `${
                    formik.values.hasshipping == 1 &&
                    formik.values.freeShipping != 1 &&
                    global.storeConfigration?.remove_shipping_fields?.value != 1 &&
                    customize.collectablethemefield != 1
                        ? ''
                        : 'd-none'
                } col-md-3 col-sm-6 col-12`,
                name: 'height',
                formik: formik,
                min: 0,
            },
            {
                label: 'Domestic Flat Shipping Price',
                type: 'text',
                placeholder: 'Enter your Shipping Price',
                class: `${
                    formik.values.hasshipping == 1 &&
                    global.storeConfigration?.remove_shipping_fields?.value != 1 &&
                    customize.externalshipingOnly != 1
                        ? ''
                        : 'd-none'
                } col-6`,
                name: 'shippingcost',
                formik: formik,
                min: 0,
            },
            {
                type: 'radio',
                noLabel: true,
                name: 'is_digital',
                int: 1,
                class: `${
                    formik.values.hasshipping == 1 &&
                    global.storeConfigration?.remove_shipping_fields?.value != 1 &&
                    customize.externalshipingOnly != 1
                        ? ''
                        : 'd-none'
                } col-6`,
                item: [
                    { id: 0, description: 'Cost' },
                    { id: 1, description: 'Percentage' },
                ],
                formik: formik,
            },
        )
    }
    if (customize.extraSection) {
        productListing.push(
            {
                label: 'Tax/Removal',
                placeholder: 'Enter Tax/Removal',
                type: 'ckeditor',
                class:
                    global.storeConfigration?.disable_tax_field?.value != 1 ? 'col-12' : 'd-none',
                name: 'customfield4',
                formik: formik,
            },
            {
                label: customize.liquidationPalletFields ? 'Shipping Terms' : 'Contacts',
                placeholder: 'Enter Shipping Details',
                type: 'ckeditor',
                class:
                    global.storeConfigration?.disable_contacts_field?.value != 1
                        ? 'col-12'
                        : 'd-none',
                name: 'customfield5',
                formik: formik,
            },
            // {
            //     label: 'Documents',
            //     placeholder: 'Enter Documents',
            //     type: 'ckeditor',
            //     class: 'col-12',
            //     name: 'customfield6',
            //     formik: formik,
            // },
            {
                type: 'uploadDropZone',
                formik: formik,
                name: 'work_loc',
                titleText: 'Upload documents',
                innerText: (
                    <div className="text-center">
                        <span className="material-icons">post_add</span>
                        <br />
                        <p className="mb-2">
                            <b>
                                <span>Upload documents</span>
                            </b>
                        </p>
                        <span>Upload a document file</span>
                    </div>
                ),
                class: 'col-12',
                folder: '',
                multiple: true,
                accept: [
                    'text/csv',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel',
                    'application/pdf',
                ],
                compressImages: false,
            },
            {
                label: 'Terms & Conditions',
                placeholder: 'Enter Terms & Conditions',
                type: 'ckeditor',
                class:
                    global.storeConfigration?.disable_terms_field?.value != 1 ? 'col-12' : 'd-none',
                name: 'customfield7',
                formik: formik,
            },
        )
    }

    if (customize.imageInfoContent) {
        productListing.push({
            type: 'misc',
            content: customize.imageInfoContent,
            class: 'col-12',
        })
    }
    if (customize.multiSectionImage) {
        if (customize.multiSectionImage?.length > 0) {
            customize.multiSectionImage.map((data, index) => {
                if (data == 'Need_Image_Upload') {
                    productListing.push(
                        {
                            type: 'uploadDropZone',
                            formik: formik,
                            name: 'sep_images',
                            titleText: 'Upload product images',
                            innerText: (
                                <div className="text-center">
                                    <span className="material-icons">add_photo_alternate</span>
                                    <br />
                                    <p className="mb-2">
                                        <b>
                                            <span>{t('upload') + ' ' + t('Image')}</span>
                                        </b>
                                    </p>
                                    <span>{t('recommended_dimen')}: 500px * 500px</span>
                                </div>
                            ),
                            class: 'col-12',
                            folder: 'product',
                            multiple: true,
                            reorder: true,
                            fileName: props.fileName,
                            accept: props.allformatimagenotneed
                                ? 'image/jpeg,image/png,image/jpg'
                                : 'image/*',
                        },
                        {
                            type: 'previousImages',
                            formik: formik,
                            reorder: true,
                            name: 'product_sep_existingimage',
                            class: 'col-12',
                            folder: 'product',
                            deleteArray: 'product_sep_deletedimage',
                        },
                    )
                } else {
                    productListing.push(
                        {
                            type: 'uploadDropZone',
                            formik: formik,
                            name: `product_image_${index}`,
                            titleText: 'Upload product images',
                            innerText: (
                                <div className="text-center">
                                    <span className="material-icons">add_photo_alternate</span>
                                    <br />
                                    <p className="mb-2">
                                        <b>
                                            <span>
                                                {t('upload') + ' ' + data + ' ' + t('Image')} *
                                            </span>
                                        </b>
                                    </p>
                                    {/* <span>Recommended dimensions: 500px * 500px</span> */}
                                </div>
                            ),
                            class: props.videoUploader ? 'col-md-3 col-6 mutlImgUpld' : 'col-6',
                            folder: 'product',
                            fileName: props.fileName,
                            accept: props.allformatimagenotneed
                                ? 'image/jpeg,image/png,image/jpg'
                                : 'image/*',
                            compressImages: true,
                        },
                        {
                            type: 'previousImages',
                            formik: formik,
                            name: `product_existing_image_${index}`,
                            class: product_id ? 'col-md-3 col-6 mutlImgUpld' : 'd-none',
                            folder: 'product',
                            deleteArray: `product_deleted_image_${index}`,
                        },
                    )
                }
            })
        }
    } else {
        productListing.push({
            type: 'uploadDropZone',
            formik: formik,
            name: 'product_image',
            titleText: 'Upload product images',
            innerText: (
                <div className="text-center">
                    <span className="material-icons">add_photo_alternate</span>
                    <br />
                    <p className="mb-2">
                        <b>
                            <span>Upload Preview Images *</span>
                        </b>
                    </p>
                    <span>Recommended dimensions: 500px * 500px</span>
                </div>
            ),
            class: props.videoUploader ? 'col-md-6 col-12' : 'col-12',
            folder: 'product',
            multiple: true,
            reorder: true,
            fileName: props.fileName,
            accept: props.allformatimagenotneed ? 'image/jpeg,image/png,image/jpg' : 'image/*',
            compressImages: customize.compress_image == 'noneed' ? false : true,
        })
    }

    if (props.videoUploader) {
        productListing.push({
            type: 'uploadDropZone',
            formik: formik,
            name: 'product_videos',
            titleText: 'Upload Preview Video',
            innerText: (
                <div className="text-center">
                    <span className="material-icons">video_call</span>
                    <br />
                    <p className="mb-2">
                        <b>
                            <span>{t('preview_video')}*</span>
                        </b>
                    </p>
                    <span>{t('recomedation_form')}: .mp4</span>
                </div>
            ),
            class: customize.multiSectionImage ? 'col-12' : 'col-md-6 col-12',
            folder: 'product',
            multiple: true,
            reorder: true,
            accept: 'video/*',
            fileName: props.fileName,
            compressImages: false,
        })
    }

    if (!customize.multiSectionImage) {
        productListing.push({
            type: 'previousImages',
            formik: formik,
            reorder: true,
            name: 'product_existingimage',
            class: props.videoUploader ? 'col-md-6 col-12' : 'col-12',
            folder: 'product',
            deleteArray: 'product_deletedimage',
        })
    }
    if (props.videoUploader) {
        productListing.push({
            type: 'previousImages',
            formik: formik,
            name: 'product_existingvideos',
            class: 'col-md-6 col-12',
            folder: 'product',
            deleteArray: 'product_deletedvideos',
        })
    }
    useEffect(() => {
        if (formik.values.end_date && formik.values.start_date) {
            if (moment(formik.values.end_date).isSameOrBefore(formik.values.start_date)) {
                formik.values.end_date = null
                return setReload(!reload)
            }
        }
    }, [formik.values.start_date, formik.values.end_date])
    const handleChange = (event, newValue) => {
        if (customize.need_more_details && newValue == 1) {
            setValue(2)
        } else {
            setValue(newValue)
        }
    }

    const validationBulkArray = Yup.object({
        csvdata: Yup.array().required('File required'),
    })

    const bulkFormik = useFormik({
        initialValues: {
            csvdata: [],
            market_status: '',
            buyerpremium_percentage: 0,
        },
        validationSchema: validationBulkArray,
        onSubmit: async (values) => {
            if (values.csvdata.length == 0) {
                setAlert('File required', 'error')
                return false
            }
            var items_limit = global.storeConfigration?.global_lot_items?.value
                ? global.storeConfigration?.global_lot_items?.value
                : 0
            if (parseInt(items_limit) > 0 && !values.csvdata[0].name.includes('.csv')) {
                readXlsxFile(values.csvdata[0]).then((rows) => {
                    total_items_csv = total_items_csv + (rows.length - 1)
                    if (parseInt(total_items_csv) <= parseInt(item_count)) {
                        setAlert('Your Lot Limit Is Reached!', 'error')
                        return handleRedirectInternal(history, `dashboard/overview`)
                    } else {
                        let formValues = values
                        formValues.startdate = edit_auction.auction_details.date_added
                        formValues.enddate = edit_auction.auction_details.date_closed
                        formValues.buyerpremium_percentage = edit_auction.auction_details
                            .la_seller_id
                            ? edit_auction.auction_details.la_seller_id
                            : 0
                        // if (auctionDetails.address) {
                        //     formValues.customfield8 = JSON.stringify({
                        //         address: auctionDetails.address,
                        //         zipcode: auctionDetails.zipcode,
                        //     })
                        // }
                        if (global.storeConfigration?.need_post_buynow?.value == 1) {
                            formValues.buynow_disable = 1
                        } else {
                            formValues.buynow_disable = 0
                        }
                        if (auctionDetails.address) {
                            formValues.customfield8 = JSON.stringify({
                                address: auctionDetails.address,
                                zipcode: auctionDetails.zipcode,
                            })
                        }
                        if (auctionDetails.state) {
                            formValues.state = auctionDetails.state
                        }
                        let formDataSend = jsonToFormData(formValues)
                        bulkUploadInventory(formDataSend)
                        setLoading(true)
                    }
                })
            } else {
                let formValues = values
                if (auctionDetails.address) {
                    formValues.customfield8 = JSON.stringify({
                        address: auctionDetails.address,
                        zipcode: auctionDetails.zipcode,
                    })
                }
                formValues.startdate = edit_auction.auction_details.date_added
                formValues.enddate = edit_auction.auction_details.date_closed
                formValues.buyerpremium_percentage = edit_auction.auction_details.la_seller_id
                    ? edit_auction.auction_details.la_seller_id
                    : 0
                if (global.storeConfigration?.need_post_buynow?.value == 0) {
                    formValues.buynow_disable = 1
                } else {
                    formValues.buynow_disable = 0
                }
                if (auctionDetails.state) {
                    formValues.state = auctionDetails.state
                }

                let formDataSend = jsonToFormData(formValues)
                bulkUploadInventory(formDataSend)
                setLoading(true)
            }
        },
    })

    const bulkListing = [
        {
            type: 'uploadDropZone',
            formik: bulkFormik,
            name: 'csvdata',
            titleText: 'Upload csv import file *',
            innerText: 'Recommended dimensions: 500px * 500px',
            class: 'col-12 bulkUploadWrpr',
            folder: 'product',
            multiple: false,
            compressImages: false,
            accept: [
                'text/csv',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel',
            ],
        },
    ]

    const downloadSample = () => {
        if (global.storeConfigration?.need_post_buynow?.value == 0) {
            window.open(`/sample/drop_shipper_listing_sample_file.xlsx`)
        } else {
            window.open(`/sample/drop_shipper_listing_sample.csv`)
        }
    }

    useEffect(() => {
        let newArray = []
        let newObj = Object.entries(formik.errors).map(([k, v]) => [k, v])
        //console.log('newObj', newObj, newArray)
    }, [formik.errors])

    useEffect(() => {
        if (customize.multiSectionImage?.length > 0) {
            customize.multiSectionImage?.map((data, index) =>
                formik.setFieldValue(`product_image_${index}`, ''),
            )
        }
    }, [customize.multiSectionImage])

    useEffect(() => {
        if (global.storeConfigration?.prefill_address?.value == '1') {
            formik.setFieldValue('address', user?.address1 + ', ' + user?.address2)
            formik.setFieldValue('location', user?.country)
            formik.setFieldValue('state', user?.state)
        }
    }, [user])

    return (
        <div className="postProductContainer">
            <Tabs
                value={value == 2 && customize.need_more_details ? 1 : value}
                onChange={handleChange}
                aria-label="buyer-dashboard"
            >
                <Tab
                    label={product_id && !repost ? 'Edit Item' : t('upload_single_ite')}
                    {...a11yProps(0)}
                />
                {!product_id && !repost && !customize.withoutAuction && (
                    <Tab label="Upload Bulk Items" {...a11yProps(1)} />
                )}
                {customize.need_more_details && (
                    <Tab
                        disabled={product_id && !repost ? false : true}
                        label="More Details"
                        {...a11yProps(2)}
                    />
                )}
            </Tabs>
            <TabPanel value={value} index={0}>
                <form className="pp-form" onSubmit={formik.handleSubmit}>
                    <div ref={lotRef}>
                        {product_id ? (
                            <div className="row">
                                {alldata ? mapData(productListing) : 'Loading...'}
                            </div>
                        ) : (
                            <div className="row">{mapData(productListing)}</div>
                        )}

                        <div className="pp-btns-wrap">
                            {/* <button type="button">Post a Live</button> */}
                            {product_id && !repost ? (
                                <>
                                    {alldata ? (
                                        <>
                                            {customize.need_more_details ? (
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                        <PrimaryButton
                                                            type="button"
                                                            label={
                                                                loading
                                                                    ? 'Loading...'
                                                                    : 'Edit Lot And Save'
                                                            }
                                                            disabled={loading}
                                                            onClick={() => {
                                                                formik.setFieldValue(
                                                                    'no_need_more_details',
                                                                    true,
                                                                )
                                                                formik.handleSubmit()
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                                        <PrimaryButton
                                                            type="button"
                                                            label={
                                                                loading
                                                                    ? 'Loading...'
                                                                    : 'Continue to More Detail'
                                                            }
                                                            disabled={loading}
                                                            onClick={() => {
                                                                formik.handleSubmit()
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <PrimaryButton
                                                    type="button"
                                                    label={
                                                        loading
                                                            ? 'Loading...'
                                                            : props.customBtnLabel
                                                            ? props.customBtnLabel
                                                            : customize.need_more_details
                                                            ? 'Continue to More Detail'
                                                            : 'Edit Lot'
                                                    }
                                                    disabled={loading}
                                                    onClick={() => {
                                                        formik.handleSubmit()
                                                    }}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </>
                            ) : (
                                <>
                                    {customize.need_more_details ? (
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 col-sm-6">
                                                <PrimaryButton
                                                    type="button"
                                                    label={
                                                        loading
                                                            ? 'Loading...'
                                                            : 'Save And Post Live'
                                                    }
                                                    disabled={loading}
                                                    onClick={() => {
                                                        formik.setFieldValue(
                                                            'market_status',
                                                            'draft',
                                                        )
                                                        formik.setFieldValue(
                                                            'no_need_more_details',
                                                            true,
                                                        )
                                                        formik.handleSubmit()
                                                        setAddMore(0)
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-6">
                                                <PrimaryButton
                                                    type="button"
                                                    label={
                                                        loading
                                                            ? 'Loading...'
                                                            : 'Continue to More Detail'
                                                    }
                                                    disabled={loading}
                                                    onClick={() => {
                                                        formik.setFieldValue(
                                                            'market_status',
                                                            'draft',
                                                        )

                                                        formik.handleSubmit()
                                                        setAddMore(0)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row">
                                            {customize.withoutAuction ? null : (
                                                <>
                                                    <div className="col-md-3">
                                                        <PrimaryButton
                                                            type="button"
                                                            label={
                                                                loading
                                                                    ? 'Loading...'
                                                                    : 'Save Draft & Add More'
                                                            }
                                                            disabled={loading}
                                                            onClick={() => {
                                                                formik.setFieldValue(
                                                                    'market_status',
                                                                    'draft',
                                                                )
                                                                formik.handleSubmit()
                                                                setAddMore(1)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <PrimaryButton
                                                            type="button"
                                                            label={
                                                                loading
                                                                    ? 'Loading...'
                                                                    : 'Publish Live & Add More'
                                                            }
                                                            disabled={loading}
                                                            onClick={() => {
                                                                formik.setFieldValue(
                                                                    'market_status',
                                                                    'open',
                                                                )
                                                                formik.handleSubmit()
                                                                setAddMore(1)
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            {global.pluginConfiguration?.reseller_listing_fee
                                                ?.enable == 1 ? null : (
                                                <div className="col-md-3">
                                                    <PrimaryButton
                                                        type="button"
                                                        label={
                                                            loading
                                                                ? 'Loading...'
                                                                : props.draft_btn_name
                                                                ? props.draft_btn_name
                                                                : t('save_draft_for_pr')
                                                        }
                                                        disabled={loading}
                                                        onClick={() => {
                                                            formik.setFieldValue(
                                                                'market_status',
                                                                'draft',
                                                            )
                                                            formik.handleSubmit()
                                                            setAddMore(0)
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <div className="col-md-3">
                                                {!user?.card_profileid ||
                                                (global.pluginConfiguration?.reseller_subscription
                                                    ?.enable &&
                                                    !user?.subscribed) ? (
                                                    <PrimaryButton
                                                        // label={
                                                        //     !user?.card_profileid && !user.subscribed
                                                        //         ? 'Subscribe and Setup bank account'
                                                        //         : !user.subscribed
                                                        //         ? 'Subscribe'
                                                        //         : 'Subscribe and Setup bank account'
                                                        // }
                                                        label={
                                                            loading
                                                                ? 'Loading...'
                                                                : props.go_live_btn_name
                                                                ? props.go_live_btn_name
                                                                : 'Publish Live'
                                                        }
                                                        disabled={loading}
                                                        onClick={() => {
                                                            setPosting_without_onboard(true)
                                                            formik.setFieldValue(
                                                                'market_status',
                                                                global.storeConfigration
                                                                    ?.posting_without_onboard
                                                                    ?.value == 1
                                                                    ? 'open'
                                                                    : 'draft',
                                                            )
                                                            formik.handleSubmit()
                                                            // if (!user?.subscribed) {
                                                            //     setAlert(
                                                            //         'Kindly subscribe for move to product in live!',
                                                            //         'warning',
                                                            //     )
                                                            //     handleRedirectInternal(
                                                            //         history,
                                                            //         'dashboard/subscription',
                                                            //     )
                                                            // } else {
                                                            //     setAlert(
                                                            //         'Kindly add account id for move to product in live',
                                                            //         'warning',
                                                            //     )
                                                            //     handleRedirectInternal(
                                                            //         history,
                                                            //         'dashboard/profile?moveto="view"',
                                                            //     )
                                                            // }
                                                        }}
                                                    />
                                                ) : (
                                                    <PrimaryButton
                                                        type="button"
                                                        label={
                                                            loading
                                                                ? 'Loading...'
                                                                : props.go_live_btn_name
                                                                ? props.go_live_btn_name
                                                                : 'Publish Live'
                                                        }
                                                        disabled={loading}
                                                        onClick={() => {
                                                            formik.setFieldValue(
                                                                'market_status',
                                                                global.pluginConfiguration
                                                                    ?.reseller_listing_fee
                                                                    ?.enable == 1
                                                                    ? 'draft'
                                                                    : 'open',
                                                            )
                                                            formik.handleSubmit()
                                                            setAddMore(0)
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="row">
                            {props.bottomErrorField && (
                                <div className="col-12">
                                    {formik.submitCount > 0 && formik.errors && (
                                        <small className="text-danger errLst">
                                            {Object.entries(formik.errors).map(([key, value]) => (
                                                <span className="errValu">
                                                    {value}
                                                    <span className="cma">,</span>&nbsp;
                                                </span>
                                            ))}
                                        </small>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </form>{' '}
                {customize.showOverallError == 1 &&
                    formik.submitCount > 0 &&
                    Object.keys(formik.errors).length > 0 && (
                        <div className="text-danger">
                            Please fill the required fields (highlighted)
                        </div>
                    )}
            </TabPanel>
            <TabPanel value={value} index={1}>
                <form className="pp-form">
                    <div>
                        <div className="row">{mapData(bulkListing)}</div>
                        <div className="pp-btns-wrap">
                            <div className="row">
                                <div className="col-md-3">
                                    <PrimaryButton
                                        type="button"
                                        label={loading ? 'Loading...' : 'Save Draft & Add More'}
                                        disabled={loading}
                                        onClick={() => {
                                            bulkFormik.setFieldValue('market_status', 'draft')
                                            bulkFormik.handleSubmit()
                                            setAddMore(1)
                                        }}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <PrimaryButton
                                        type="button"
                                        label={loading ? 'Loading...' : 'Publish Live & Add More'}
                                        disabled={loading}
                                        onClick={() => {
                                            bulkFormik.setFieldValue('market_status', 'open')
                                            bulkFormik.handleSubmit()
                                            setAddMore(1)
                                        }}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <PrimaryButton
                                        type="button"
                                        label={loading ? 'Loading...' : t('save_draft_for_pr')}
                                        disabled={loading}
                                        onClick={() => {
                                            bulkFormik.setFieldValue('market_status', 'draft')
                                            bulkFormik.handleSubmit()
                                            setAddMore(0)
                                        }}
                                    />
                                </div>
                                <div className="col-md-3">
                                    {!user?.card_profileid ? (
                                        <PrimaryButton
                                            label="Setup bank account"
                                            onClick={() => {
                                                setAlert(
                                                    'Kindly add account id to continue!',
                                                    'warning',
                                                )
                                                return handleRedirectInternal(
                                                    history,
                                                    'dashboard/profile',
                                                )
                                            }}
                                        />
                                    ) : (
                                        <PrimaryButton
                                            type="button"
                                            label={
                                                loading
                                                    ? 'Loading...'
                                                    : props.go_live_btn_name
                                                    ? props.go_live_btn_name
                                                    : 'Publish Live'
                                            }
                                            disabled={loading}
                                            onClick={() => {
                                                bulkFormik.setFieldValue('market_status', 'open')
                                                bulkFormik.handleSubmit()
                                                setAddMore(0)
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>{' '}
                <br />
                <PrimaryButton onClick={downloadSample} label="Download Sample" />
                To use (CSV format). Other supported file formats: XLSX, and Text (tab delimited).
                <div className="mt-3">
                    <h6>Choose condition Id From Below Lists</h6>
                    <CategoryLookup
                        type="condtions"
                        category={allCondition
                            .filter(
                                (maincat) =>
                                    maincat.active === 1 ||
                                    (formik.values.conditionTypeId &&
                                        formik.values.conditionTypeId === maincat.id),
                            )
                            .map((location) => {
                                let conditionChanged = {}
                                conditionChanged.name = location.description
                                conditionChanged.id = location.id
                                return conditionChanged
                            })}
                    />
                </div>
                <div className="mt-3">
                    <h6>Choose Category & Sub-Category Id From Below Lists</h6>
                    <CategoryLookup category={categories} subCategory={subCategory} />
                </div>
            </TabPanel>

            <TabPanel value={value} index={2}>
                <Moredetails
                    product_id={product_id}
                    repost={repost}
                    formik={formik}
                    loading={loading}
                    condition={customize.customCondition}
                    setAddMore={setAddMore}
                />
            </TabPanel>

            <Popup
                open={listingFeePopup}
                handleClose={(e) => setListingFeePopup(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Listing Fee"
            >
                <>
                    <ListingFee product_id={listingFeePopup} />
                </>
            </Popup>
        </div>
    )
}

export default PostProduct
