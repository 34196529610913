import React, { useState, useEffect, useContext, useRef } from 'react'
// import openSocket from 'socket.io-client'
import { useHistory } from 'react-router-dom'
import SubLots from './SubLots'
import ProductAuctionTop from './ProductAuctionTop'
import SlidePanel from '../../../Component/SlidePanel'
import './product.css'
import _ from 'lodash'
import Toaster from '../../../Component/Toaster'

import { socket, socketAuction } from '../../../Product/common/socket'

import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'

import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import {
    make_an_offer_update_socket_multiple,
    messageHandler,
} from '../../../Product/common/socketHandler'
import Loaders from '../../../Product/components/molecules/Loaders'
import { Link } from 'react-router-dom'
import Donation from '../../../Component/Donation'
import Layout from '../Layout'
import UserActivity from '../../../utils/CommonFunctionality/UserActivity'

const ProductAuctionComponent = (props) => {
    const authContext = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)

    const { user, isAuthenticated } = authContext
    const { setAlert } = alertContext
    const [audio] = useState(
        global.storeConfigration?.bidding_sound?.value == 1 ? new Audio('/audio/ding.mp3') : '',
    )
    const {
        search_allauctions,
        search_allauctionproducts,
        edit_auction,
        getLotList,
        getEditAuction,
    } = productContext

    let history = useHistory()
    const [auctionData, setAuctionData] = useState({})
    const [minMaxDate, setMinMaxDate] = useState({})
    const [subLotData, setSubLotData] = useState([])
    const [upcomming, setUpcomming] = useState(false)
    const [subLotDataCount, setSubLotDataCount] = useState(0)
    let auctionid = props.match.params.id
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [subLotSearch, setSubLotSearch] = useState({
        page: 1,
        sortBy: 7,
        lotof: auctionid,
        limit: 50,
        market_status: 'open',
        auction_io: 1,
    })

    const [auctionLoading, setAuctionLoading] = useState(true)
    const [productLoading, setProductLoading] = useState(true)

    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })

    useEffect(() => {
        getEditAuction({ auctionid: props.match.params.id }, 'auctionView')
        setAuctionLoading(true)
    }, [props.match.params.id, isAuthenticated])

    useEffect(() => {
        getLotList(subLotSearch, 'auctionSubView')
        setProductLoading(true)
    }, [subLotSearch, isAuthenticated])

    useEffect(() => {
        if (search_allauctionproducts.from === 'auctionSubView') {
            setSubLotData(search_allauctionproducts.results)
            setSubLotDataCount(search_allauctionproducts.total_only)
            setTimeout(() => {
                setProductLoading(false)
            }, 1500)
        }
    }, [search_allauctionproducts])

    useEffect(() => {
        if (edit_auction.from === 'auctionView') {
            if (edit_auction.auction_details) {
                setAuctionData(edit_auction.auction_details)
                setMinMaxDate(edit_auction.min_max_date_closed)
            }
            setTimeout(() => {
                setAuctionLoading(false)
            }, 1500)
        }
    }, [edit_auction])

    const handlePage = (pageNumber) => {
        document.getElementById('sublot').scrollIntoView()
        setSubLotSearch({ ...subLotSearch, page: pageNumber })
    }

    const handleClose = () => setSelectedProduct(null)

    const handleClick = async (id) => {
        setSelectedProduct(id)
    }

    const viewProductRef = useRef(subLotData)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = subLotData
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSubLotData,
            type,
        )
    }

    const make_an_offer_update_new = (message, type) => {
        make_an_offer_update_socket_multiple(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSubLotData,
            type,
        )
    }

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            // getLotList(subLotSearch, 'auctionView')
            data.id = data.pid
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socket.on('cancelbidemitted', (data) => {
            handler(data, 'cancelbidemitted')
        })

        socket.on('make_an_offer_update', (data) => {
            make_an_offer_update_new(data, 'make_an_offer')
        })

        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.off('cancelbidemitted', (data) => {
                handler(data, 'cancelbidemitted')
            })

            socket.off('make_an_offer_update', (data) => {
                make_an_offer_update_new(data, 'make_an_offer')
            })

            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    return (
        <Layout props={props}>
            <UserActivity page="auction_view_page" />
            <div className="customContainer pt-3 auctionCnt">
                {auctionLoading ? (
                    <Loaders name="product_view" isLoading={auctionLoading} loop={1} />
                ) : (
                    <>
                        <div className="co-breadcrumb">
                            <Link to="/auctions">Search</Link> / {auctionData?.title}
                        </div>
                        <ProductAuctionTop
                            auctionData={auctionData}
                            product_id={auctionid}
                            executeScroll={executeScroll}
                            minMaxDateClosed={minMaxDate}
                            setUpcomming={setUpcomming}
                        />
                        <div ref={myRef}></div>
                    </>
                )}
                {productLoading ? (
                    <Loaders name="table" isLoading={productLoading} loop={1} />
                ) : (
                    <>
                        <SubLots
                            selectedPostion={handleClick}
                            handleClick={handleClick}
                            title={auctionData.title}
                            auctionid={auctionid}
                            lotData={subLotData}
                            subLotDataCount={subLotDataCount}
                            subLotSearch={subLotSearch}
                            setSubLotSearch={setSubLotSearch}
                            showviews={
                                global?.storeConfigration?.needgridview?.value
                                    ? parseInt(global.storeConfigration.needgridview.value)
                                    : 0
                            }
                            setSubLotData={setSubLotData}
                            audio={audio}
                            upcomming={upcomming}
                        />
                        <SlidePanel
                            type="right"
                            selectedLot={selectedProduct}
                            handleClose={handleClose}
                            from={''}
                            getAllProducts={() => false}
                            audio={audio}
                            enableZoomOnHover={true}
                        />
                    </>
                )}
            </div>
        </Layout>
    )
}

export default ProductAuctionComponent
